import { Button, Flex, HStack, Spacer, Stack, Text } from '@chakra-ui/react'

import { config } from 'config'

const boldGradientTextStyle = {
  fontWeight: 'bold',
  backgroundImage:
    'linear-gradient(50deg, #F658A8, #ff5b8a 49%, #ff5b8a 53%, #ff5b8a 55%, #fba64b 77%, #f99b52)',
  backgroundClip: 'text',
  color: 'transparent',
}

export const WaitlistBar = () => {
  return (
    <Flex bg="#1A013F" direction="column" mb={4}>
      <Flex py={4} px={8} direction={['column', 'row']}>
        <Stack mr={[0, 4]}>
          <Text fontSize="xl" sx={boldGradientTextStyle}>
            Meet Gamma, the fast alternative to slides
          </Text>
          <Text color="gray.200">Write like a doc, present like a deck.</Text>
        </Stack>
        <Spacer />
        <HStack mt={[2, 0]}>
          <Button as="a" href="/signup">
            Make your own
          </Button>
          <Button
            as="a"
            cursor="pointer"
            variant="ghost"
            colorScheme="whiteAlpha"
            onClick={() => {
              window.location.href = `${config.API_HOST}/logout`
            }}
          >
            Sign out
          </Button>
        </HStack>
      </Flex>
    </Flex>
  )
}
