import { HStack, StackProps, Text } from '@chakra-ui/react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface SidebarItemLabelProps extends StackProps {
  icon?: IconProp
  title: string
  faClassName?: string
}
export const SidebarItemLabel = ({
  icon,
  title,
  faClassName,
  ...props
}: SidebarItemLabelProps) => {
  return (
    <HStack {...props}>
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          fixedWidth
          className={faClassName ? faClassName : undefined}
        />
      )}
      <Text textOverflow="ellipsis" overflowX="hidden" ml={2}>
        {title}
      </Text>
    </HStack>
  )
}
