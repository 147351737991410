import isNil from 'lodash/isNil'
import uniqBy from 'lodash/uniqBy'

import { config } from 'config'
import { Deck, DeckCategory } from 'modules/contentful'

import {
  Api_Deck,
  Api_DeckCategoryDecksCollection,
  SimplifiedCategory,
} from './types'

// Filters out null decks
const getNonNullDecksForCategory = (cat: DeckCategory) =>
  uniqBy(
    cat?.decksCollection?.items.filter((d): d is Deck => !isNil(d)) || [],
    'docId'
  )

// IMPORTANT: filter out unpublished categories
// https://www.contentful.com/developers/docs/references/graphql/#/reference/graphql-errors/graphql-errors-explained
// A null value is returned on the field for unresolvable links (i.e. if the category
// links to an unpublished deck).
export const getSimplifiedCategories = (
  categories?: Api_DeckCategoryDecksCollection
): SimplifiedCategory[] => {
  if (!categories || categories.length === 0) return []
  return uniqBy(
    categories
      .filter((cat): cat is DeckCategory => !isNil(cat)) // Remove null categories
      .filter((cat) => getNonNullDecksForCategory(cat).length > 0) // Remove categories with no decks
      .map((cat) => {
        return {
          id: cat.sys.id,
          title: cat.title,
          description: cat.description?.json,
          decks: getNonNullDecksForCategory(cat), // Remove unpublished decks
        }
      }),
    'id'
  )
}

const DEFAULT_STAGING_DOC_ID = 'oltspxg8obq2nzc'

export const getDocIdForEnv = (deck?: Api_Deck) => {
  if (!deck) {
    console.warn('[getDocIdForEnv] Deck is undefined. This is unexpected.')
    return
  }
  if (config.APPLICATION_ENVIRONMENT !== 'production') {
    return deck.stagingDocId || DEFAULT_STAGING_DOC_ID
  }
  return deck.docId
}
