import { Container, Flex, Skeleton } from '@chakra-ui/react'
import Head from 'next/head'
import { useState } from 'react'

import { DocsViewSkeleton } from './components/DocsView/DocsViewSkeleton'
import { SidebarSkeleton } from './components/Sidebar'

export const HomeSkeleton = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false)
  return (
    <Flex data-testid="home" width="100%" height="100%">
      <Head>
        <title>Gamma</title>
      </Head>

      <Flex
        direction="column"
        zIndex="overlay"
        h="100%"
        position={{ base: 'fixed', md: 'sticky' }}
        top={0}
        w="300px"
        minW="300px"
        bg="white"
        shadow={{
          sm: 'lg',
          md: 'none',
        }}
        transform={
          isSidebarVisible
            ? {
                base: 'translate(0,0px)',
                md: 'translate(0,0)',
              }
            : {
                base: 'translate(-100%,0px)',
                md: 'translate(0,0)',
              }
        }
        transition="transform .2s ease"
      >
        <Flex
          overflowY="auto"
          h="100vh"
          p={6}
          py={2}
          direction="column"
          alignContent={'space-between'}
        >
          <SidebarSkeleton setIsSidebarVisible={setIsSidebarVisible} />
        </Flex>
      </Flex>
      <Flex
        data-testid="doc-root-main"
        flex="1"
        px={2}
        py={6}
        w="100vw"
        bg="gray.100"
        borderTopLeftRadius="15px"
        borderBottomLeftRadius="15px"
        direction="column"
        marginLeft={{
          base: '0',
        }}
        minH="var(--100vh)"
        transition="margin .2s ease"
      >
        <Container maxW="80em">
          <Skeleton height="42px" w="30%" mb={2} />
          <Skeleton height="24px" w="100%" mb={2} />
          <Skeleton height="24px" w="100%" mb={10} />

          <Flex width="100%">
            <DocsViewSkeleton />
          </Flex>
        </Container>
      </Flex>
    </Flex>
  )
}
