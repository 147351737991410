import { Alert, AlertIcon, Box, Flex, Text } from '@chakra-ui/react'
import { DOC_DISPLAY_NAME_PLURAL, LinkCopier } from '@gamma-app/ui'

import { ExistingWorkspace } from 'modules/api'
import { generateInviteUrl } from 'utils/url'

export const InviteLink = ({ workspace }: { workspace: ExistingWorkspace }) => {
  const inviteUrl = generateInviteUrl(workspace)

  return (
    <>
      <Text my={2} fontSize="sm" color="gray.500">
        Or you can invite them with this share link
      </Text>
      <Flex mb={4}>
        <LinkCopier url={inviteUrl} />
      </Flex>
      <Alert mt={2} status="warning" fontSize="sm" borderRadius={4}>
        <AlertIcon />
        <Box>
          <strong>Heads up! This is a special link.</strong> Anyone with this
          link can join your workspace and will be able to view{' '}
          {DOC_DISPLAY_NAME_PLURAL} that are not private.
        </Box>
      </Alert>
    </>
  )
}
