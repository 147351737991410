import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { CHANNEL_DISPLAY_NAME, DOC_DISPLAY_NAME_PLURAL } from '@gamma-app/ui'
import React, { useCallback } from 'react'

import { Channel, useArchiveChannelMutation } from 'modules/api'

export const DeleteChannelModal = ({
  channel,
  workspaceId,
  onClose,
}: {
  channel: Channel
  workspaceId?: string
  onClose: () => void
}) => {
  const [archiveChannel] = useArchiveChannelMutation()
  const toast = useToast()
  const handleDeleteChannel = useCallback(() => {
    if (!workspaceId || !channel?.id) {
      console.error(
        `[onDeleteChannelClick] Cannot delete channel. OrgId: "${workspaceId}" channelId: "${channel?.id}"`
      )
      return
    }
    archiveChannel({
      variables: {
        channelId: channel.id,
        workspaceId: workspaceId as string,
      },
    }).then(() => {
      toast({
        title: `Channel ${CHANNEL_DISPLAY_NAME} successfully deleted from workspace.`,
        status: 'success',
        duration: 3000,
        position: 'top',
      })
    })
  }, [channel, toast, archiveChannel, workspaceId])

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={true}
      onClose={onClose}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete {channel.name}?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={2}>
            This {CHANNEL_DISPLAY_NAME} will be permanently deleted for all
            users.
          </Text>
          <Text mb={2}>
            Any {DOC_DISPLAY_NAME_PLURAL} in this {CHANNEL_DISPLAY_NAME} will
            lose their association with it.
          </Text>
          <Text color="red.500">This action cannot be undone.</Text>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="ghost"
              colorScheme="red"
              onClick={() => {
                handleDeleteChannel()
                onClose()
              }}
            >
              Delete {CHANNEL_DISPLAY_NAME}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
