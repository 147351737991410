import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useSessionStorage } from 'utils/hooks/useSessionStorage'
import { isValidHomeUrl } from 'utils/url'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

export const useLastHomeUrlListener = (shouldUpdateOnRouteChanges: boolean) => {
  const [_, setLastHomeUrl] = useSessionStorage<string | null>(
    USER_SETTINGS_CONSTANTS.lastHomeUrl,
    '/'
  )
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (!shouldUpdateOnRouteChanges) return
      if (isValidHomeUrl(url)) {
        setLastHomeUrl(url)
      }
    }
    router.events.on('routeChangeStart', handleRouteChange)
    router.events.on('hashChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
      router.events.off('hashChangeStart', handleRouteChange)
    }
  }, [router.events, setLastHomeUrl, shouldUpdateOnRouteChanges])
}
