import { DocSortField, SortDirection } from 'modules/api'
import { GraphqlUser } from 'modules/user'

import { GraphqlFiltersType } from './types'

export const isDocUserFilter = (filterBy) =>
  filterBy === 'recent' || filterBy === 'favorites'

export const getGraphqlFilters = (user?: GraphqlUser): GraphqlFiltersType => {
  return {
    all: {
      ready: true,
      filter: {},
    },
    recent: {
      ready: true,
      filter: {
        sortBy: {
          field: DocSortField.LastViewed,
          direction: SortDirection.Desc,
        },
      },
    },
    private: {
      ready: true,
      filter: {
        isPrivate: true,
      },
    }, // Blocked by https://linear.app/gamma-app/issue/G-1549/be-allow-me-to-get-all-the-private-memos
    createdByYou: {
      ready: Boolean(user),
      filter: {
        createdBy: user?.id,
      },
    },
    favorites: {
      ready: true,
      filter: {
        sortBy: {
          // This is not sortable by any field other than favorited date.
          // Blocked by https://linear.app/gamma-app/issue/G-1547/be-make-it-so-that-you-can-filter-by-favorited-items-and-sort-them-by
          field: DocSortField.Favorited,
          direction: SortDirection.Desc,
        },
      },
    },
  }
}
