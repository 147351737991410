import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

import { DocSortField, Doc, GetDocsQueryVariables } from 'modules/api'

export type ViewPreferenceTypes = 'list' | 'grid'

export type FilterByTypes =
  | 'all'
  | 'recent'
  | 'private'
  | 'createdByYou'
  | 'favorites'
export type FilterInfo = {
  filter: GetDocsQueryVariables
  ready: boolean
}
export type GraphqlFiltersType = {
  [key in FilterByTypes]: FilterInfo
}
export type FilterByOptionsMap = {
  [key in FilterByTypes]: {
    label: string
    icon: FontAwesomeIconProps['icon']
  }
}

export type FilterByOption = {
  key: FilterByTypes
  label: string
  icon: FontAwesomeIconProps['icon']
}

export type DateDisplayOptionType = DocSortField

type SortByOption = {
  key: DateDisplayOptionType
  label: string
}
export type SortByOptions = SortByOption[]
export type ChannelDocDragItem = Doc

export const ChannelDocDragItemType = 'ChannelDocDragItemType'
