import { Box, Grid, HStack, Stack, Text } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AvatarGroupAutoOverflow, DocumentGridItem } from '@gamma-app/ui'
import NextLink from 'next/link'

import placeholderBackground from 'gamma_components/placeholderBackground.svg'
import { DocActivity, User } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { GraphqlUser, useUserContext } from 'modules/user'
import { useDocMutations } from 'sections/home_v2/hooks/useDocMutations'
import { FilterByTypes } from 'sections/home_v2/types'
import { moveItemWithMatchingIdToEnd } from 'utils/array'
import { generateDocUrl } from 'utils/url'

import { useFetchThumbnails } from '../hooks'

const MAX_ACTIVE_EDITORS = 3
const MAX_ACTIVE_PRESENTERS = 3

const EditorsBlock = ({
  editors,
  user,
}: {
  editors: User[]
  user?: GraphqlUser
}) => {
  const editorList = moveItemWithMatchingIdToEnd(editors, user?.id)
  return (
    <>
      <AvatarGroupAutoOverflow
        size="xs"
        shouldAnimate={false}
        max={MAX_ACTIVE_EDITORS}
        avatars={editorList.map(({ displayName, profileImageUrl, id }) => {
          return {
            name: displayName || '',
            id,
            profileImageUrl,
            color: 'white',
          }
        })}
      />
      <Stack spacing={0}>
        <Text fontSize="xs" color="gray.500">
          {editorList.length > 0 &&
            (editorList.length === 1
              ? '1 person is'
              : `${editorList.length} people are`)}{' '}
          currently editing.
        </Text>
      </Stack>
    </>
  )
}

const PresentersBlock = ({
  presenters,
  user,
}: {
  presenters: User[]
  user?: GraphqlUser
}) => {
  const presenterList = moveItemWithMatchingIdToEnd(presenters, user?.id)

  return (
    <>
      <AvatarGroupAutoOverflow
        shouldAnimate={false}
        size="xs"
        max={MAX_ACTIVE_PRESENTERS}
        avatars={presenterList.map(({ displayName, profileImageUrl, id }) => {
          return {
            name: displayName || '',
            id,
            profileImageUrl,
            color: 'white',
          }
        })}
      />
      <Stack spacing={0}>
        <Text fontSize="xs" color="gray.500">
          {presenterList.length > 0 &&
            (presenterList.length === 1
              ? '1 person is'
              : `${presenterList.length} people are`)}{' '}
          presenting now
        </Text>
      </Stack>
    </>
  )
}
export const ChannelDocActivity = ({
  activeDocs,
  filterBy,
}: {
  activeDocs: DocActivity[]
  filterBy: FilterByTypes
}) => {
  const { fetchThumbnails, getThumbnails } = useFetchThumbnails()
  const screenshotsEnabled = useFeatureFlag('screenshotsEnabled')

  const { user } = useUserContext()
  const { favoriteDoc } = useDocMutations({
    refetchQueries: ['GetDocs', 'GetChannelDocActivity'],
  })

  return (
    <Box
      bg="blackAlpha.100"
      my={4}
      pt={2}
      borderRadius="md"
      border="1px solid #000"
      borderColor="blackAlpha.100"
    >
      <Text px={6} fontSize="sm" fontWeight="600" color="gray.600" mt={2}>
        <FontAwesomeIcon icon={regular('signal-stream')} /> Active now
      </Text>
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
          xl: 'repeat(4, 1fr)',
          '2xl': 'repeat(5, 1fr)',
        }}
        gap={6}
        p={6}
        pt={3}
      >
        {activeDocs?.map((activity) => {
          const { doc, editors, presenters } = activity
          const { title, id, titleCard, createdTime } = doc
          const thumbnailImageUrl = {
            src: screenshotsEnabled
              ? titleCard?.previewUrl
              : placeholderBackground,
            fallbackSrc: placeholderBackground.src,
          }
          const isPresenting = presenters && presenters.length > 0
          const isEditing = !isPresenting && editors && editors.length > 0
          const url = generateDocUrl({ docId: id })
          const activityComponent = (
            <HStack px={2} py={1} bg="green.100" borderRadius="md" w="100%">
              {isEditing && <EditorsBlock editors={editors} user={user} />}
              {isPresenting && (
                <PresentersBlock presenters={presenters} user={user} />
              )}
            </HStack>
          )

          return (
            <DocumentGridItem
              key={id}
              createdTime={createdTime}
              title={title as string}
              NextLink={NextLink}
              href={url}
              id={id}
              thumbnailImageUrl={thumbnailImageUrl}
              // onFavorite={} // TODO: Add Favorite
              isFavorited={doc.docUser?.favorited}
              onFavorite={favoriteDoc(new Date(), doc)}
              onUnfavorite={favoriteDoc(null, doc)}
              menuEnabled={false}
              activity={activityComponent}
              cardThumbnails={[]}
              onMouseEnter={() => {
                // re-enable when we've fixed
                // fetchThumbnails(doc.id)
              }}
            />
          )
        })}
      </Grid>
    </Box>
  )
}
