import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z,
   *     compliant with the 'date-time' format outlined in section 5.6 of
   *     the RFC 3339 profile of the ISO 8601 standard for representation
   *     of dates and times using the Gregorian calendar.
   */
  DateTime: any;
  /** The 'Dimension' type represents dimensions as whole numeric values between `1` and `4000`. */
  Dimension: any;
  /** The 'HexColor' type represents color in `rgb:ffffff` string format. */
  HexColor: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The 'Quality' type represents quality as whole numeric values between `1` and `100`. */
  Quality: any;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename?: 'Asset';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  linkedFrom?: Maybe<AssetLinkingCollections>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  transform?: Maybe<ImageTransformOptions>;
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AssetCollection = {
  __typename?: 'AssetCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Asset>>;
};

export type AssetFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  url_exists?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  url_not?: Maybe<Scalars['String']>;
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  url_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  url_contains?: Maybe<Scalars['String']>;
  url_not_contains?: Maybe<Scalars['String']>;
  size_exists?: Maybe<Scalars['Boolean']>;
  size?: Maybe<Scalars['Int']>;
  size_not?: Maybe<Scalars['Int']>;
  size_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  size_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  size_gt?: Maybe<Scalars['Int']>;
  size_gte?: Maybe<Scalars['Int']>;
  size_lt?: Maybe<Scalars['Int']>;
  size_lte?: Maybe<Scalars['Int']>;
  contentType_exists?: Maybe<Scalars['Boolean']>;
  contentType?: Maybe<Scalars['String']>;
  contentType_not?: Maybe<Scalars['String']>;
  contentType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contentType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contentType_contains?: Maybe<Scalars['String']>;
  contentType_not_contains?: Maybe<Scalars['String']>;
  fileName_exists?: Maybe<Scalars['Boolean']>;
  fileName?: Maybe<Scalars['String']>;
  fileName_not?: Maybe<Scalars['String']>;
  fileName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fileName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fileName_contains?: Maybe<Scalars['String']>;
  fileName_not_contains?: Maybe<Scalars['String']>;
  width_exists?: Maybe<Scalars['Boolean']>;
  width?: Maybe<Scalars['Int']>;
  width_not?: Maybe<Scalars['Int']>;
  width_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  width_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  width_gt?: Maybe<Scalars['Int']>;
  width_gte?: Maybe<Scalars['Int']>;
  width_lt?: Maybe<Scalars['Int']>;
  width_lte?: Maybe<Scalars['Int']>;
  height_exists?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
  height_not?: Maybe<Scalars['Int']>;
  height_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  height_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  height_gt?: Maybe<Scalars['Int']>;
  height_gte?: Maybe<Scalars['Int']>;
  height_lt?: Maybe<Scalars['Int']>;
  height_lte?: Maybe<Scalars['Int']>;
  OR?: Maybe<Array<Maybe<AssetFilter>>>;
  AND?: Maybe<Array<Maybe<AssetFilter>>>;
};

export type AssetLinkingCollections = {
  __typename?: 'AssetLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  deckCollection?: Maybe<DeckCollection>;
};


export type AssetLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsDeckCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum AssetOrder {
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ContentfulMetadata = {
  __typename?: 'ContentfulMetadata';
  tags: Array<Maybe<ContentfulTag>>;
};

export type ContentfulMetadataFilter = {
  tags_exists?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<ContentfulMetadataTagsFilter>;
};

export type ContentfulMetadataTagsFilter = {
  id_contains_all?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains_some?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains_none?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *     Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename?: 'ContentfulTag';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type Deck = Entry & {
  __typename?: 'Deck';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<DeckLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  docId?: Maybe<Scalars['String']>;
  previewCardId?: Maybe<Scalars['String']>;
  stagingDocId?: Maybe<Scalars['String']>;
  isNew?: Maybe<Scalars['Boolean']>;
  previewImage?: Maybe<Asset>;
  authorDisplayName?: Maybe<Scalars['String']>;
  authorImage?: Maybe<Asset>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckDocIdArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckPreviewCardIdArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckStagingDocIdArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckIsNewArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckPreviewImageArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckAuthorDisplayNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckAuthorImageArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

/** Categories under which we can sort decks, e.g. "Popular decks" [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCategory) */
export type DeckCategory = Entry & {
  __typename?: 'DeckCategory';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<DeckCategoryLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  parentCollection?: Maybe<DeckCollection>;
  description?: Maybe<DeckCategoryDescription>;
  decksCollection?: Maybe<DeckCategoryDecksCollection>;
  priority?: Maybe<Scalars['Int']>;
};


/** Categories under which we can sort decks, e.g. "Popular decks" [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCategory) */
export type DeckCategoryLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Categories under which we can sort decks, e.g. "Popular decks" [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCategory) */
export type DeckCategoryTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Categories under which we can sort decks, e.g. "Popular decks" [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCategory) */
export type DeckCategoryParentCollectionArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** Categories under which we can sort decks, e.g. "Popular decks" [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCategory) */
export type DeckCategoryDescriptionArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Categories under which we can sort decks, e.g. "Popular decks" [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCategory) */
export type DeckCategoryDecksCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** Categories under which we can sort decks, e.g. "Popular decks" [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCategory) */
export type DeckCategoryPriorityArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type DeckCategoryCollection = {
  __typename?: 'DeckCategoryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<DeckCategory>>;
};

export type DeckCategoryDecksCollection = {
  __typename?: 'DeckCategoryDecksCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Deck>>;
};

export type DeckCategoryDescription = {
  __typename?: 'DeckCategoryDescription';
  json: Scalars['JSON'];
  links: DeckCategoryDescriptionLinks;
};

export type DeckCategoryDescriptionAssets = {
  __typename?: 'DeckCategoryDescriptionAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type DeckCategoryDescriptionEntries = {
  __typename?: 'DeckCategoryDescriptionEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type DeckCategoryDescriptionLinks = {
  __typename?: 'DeckCategoryDescriptionLinks';
  entries: DeckCategoryDescriptionEntries;
  assets: DeckCategoryDescriptionAssets;
};

export type DeckCategoryFilter = {
  parentCollection?: Maybe<CfDeckCollectionNestedFilter>;
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  parentCollection_exists?: Maybe<Scalars['Boolean']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  decksCollection_exists?: Maybe<Scalars['Boolean']>;
  priority_exists?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
  priority_not?: Maybe<Scalars['Int']>;
  priority_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  priority_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  priority_gt?: Maybe<Scalars['Int']>;
  priority_gte?: Maybe<Scalars['Int']>;
  priority_lt?: Maybe<Scalars['Int']>;
  priority_lte?: Maybe<Scalars['Int']>;
  OR?: Maybe<Array<Maybe<DeckCategoryFilter>>>;
  AND?: Maybe<Array<Maybe<DeckCategoryFilter>>>;
};

export type DeckCategoryLinkingCollections = {
  __typename?: 'DeckCategoryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type DeckCategoryLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum DeckCategoryOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  PriorityAsc = 'priority_ASC',
  PriorityDesc = 'priority_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A collection of decks, divided into categories. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCollection) */
export type DeckCollection = Entry & {
  __typename?: 'DeckCollection';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<DeckCollectionLinkingCollections>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


/** A collection of decks, divided into categories. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCollection) */
export type DeckCollectionLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A collection of decks, divided into categories. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCollection) */
export type DeckCollectionIdArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A collection of decks, divided into categories. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCollection) */
export type DeckCollectionNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A collection of decks, divided into categories. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCollection) */
export type DeckCollectionDescriptionArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type DeckCollectionCollection = {
  __typename?: 'DeckCollectionCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<DeckCollection>>;
};

export type DeckCollectionFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  id_exists?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  id_not?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains?: Maybe<Scalars['String']>;
  id_not_contains?: Maybe<Scalars['String']>;
  name_exists?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<DeckCollectionFilter>>>;
  AND?: Maybe<Array<Maybe<DeckCollectionFilter>>>;
};

export type DeckCollectionLinkingCollections = {
  __typename?: 'DeckCollectionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  deckCategoryCollection?: Maybe<DeckCategoryCollection>;
};


export type DeckCollectionLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type DeckCollectionLinkingCollectionsDeckCategoryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum DeckCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type DeckLinkingCollections = {
  __typename?: 'DeckLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  deckCategoryCollection?: Maybe<DeckCategoryCollection>;
};


export type DeckLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type DeckLinkingCollectionsDeckCategoryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type Entry = {
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
};

export type EntryCollection = {
  __typename?: 'EntryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Entry>>;
};

export type EntryFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  OR?: Maybe<Array<Maybe<EntryFilter>>>;
  AND?: Maybe<Array<Maybe<EntryFilter>>>;
};

export enum EntryOrder {
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Container for the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStarted) */
export type GettingStarted = Entry & {
  __typename?: 'GettingStarted';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<GettingStartedLinkingCollections>;
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  optionsCollection?: Maybe<GettingStartedOptionsCollection>;
};


/** Container for the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStarted) */
export type GettingStartedLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Container for the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStarted) */
export type GettingStartedIdArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Container for the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStarted) */
export type GettingStartedTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Container for the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStarted) */
export type GettingStartedOptionsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type GettingStartedCollection = {
  __typename?: 'GettingStartedCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<GettingStarted>>;
};

export type GettingStartedFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  id_exists?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  id_not?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains?: Maybe<Scalars['String']>;
  id_not_contains?: Maybe<Scalars['String']>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  optionsCollection_exists?: Maybe<Scalars['Boolean']>;
  OR?: Maybe<Array<Maybe<GettingStartedFilter>>>;
  AND?: Maybe<Array<Maybe<GettingStartedFilter>>>;
};

export type GettingStartedLinkingCollections = {
  __typename?: 'GettingStartedLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type GettingStartedLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type GettingStartedOptionsCollection = {
  __typename?: 'GettingStartedOptionsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<GettingStartedStep>>;
};

export enum GettingStartedOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** One of the options in the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStartedStep) */
export type GettingStartedStep = Entry & {
  __typename?: 'GettingStartedStep';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<GettingStartedStepLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<GettingStartedStepBody>;
  colorScheme?: Maybe<Scalars['String']>;
  previewImage?: Maybe<Scalars['String']>;
  iconName?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
};


/** One of the options in the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStartedStep) */
export type GettingStartedStepLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** One of the options in the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStartedStep) */
export type GettingStartedStepTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** One of the options in the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStartedStep) */
export type GettingStartedStepBodyArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** One of the options in the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStartedStep) */
export type GettingStartedStepColorSchemeArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** One of the options in the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStartedStep) */
export type GettingStartedStepPreviewImageArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** One of the options in the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStartedStep) */
export type GettingStartedStepIconNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** One of the options in the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStartedStep) */
export type GettingStartedStepActionArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type GettingStartedStepBody = {
  __typename?: 'GettingStartedStepBody';
  json: Scalars['JSON'];
  links: GettingStartedStepBodyLinks;
};

export type GettingStartedStepBodyAssets = {
  __typename?: 'GettingStartedStepBodyAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type GettingStartedStepBodyEntries = {
  __typename?: 'GettingStartedStepBodyEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type GettingStartedStepBodyLinks = {
  __typename?: 'GettingStartedStepBodyLinks';
  entries: GettingStartedStepBodyEntries;
  assets: GettingStartedStepBodyAssets;
};

export type GettingStartedStepCollection = {
  __typename?: 'GettingStartedStepCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<GettingStartedStep>>;
};

export type GettingStartedStepFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  body_exists?: Maybe<Scalars['Boolean']>;
  body_contains?: Maybe<Scalars['String']>;
  body_not_contains?: Maybe<Scalars['String']>;
  colorScheme_exists?: Maybe<Scalars['Boolean']>;
  colorScheme?: Maybe<Scalars['String']>;
  colorScheme_not?: Maybe<Scalars['String']>;
  colorScheme_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  colorScheme_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  colorScheme_contains?: Maybe<Scalars['String']>;
  colorScheme_not_contains?: Maybe<Scalars['String']>;
  previewImage_exists?: Maybe<Scalars['Boolean']>;
  previewImage?: Maybe<Scalars['String']>;
  previewImage_not?: Maybe<Scalars['String']>;
  previewImage_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  previewImage_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  previewImage_contains?: Maybe<Scalars['String']>;
  previewImage_not_contains?: Maybe<Scalars['String']>;
  iconName_exists?: Maybe<Scalars['Boolean']>;
  iconName?: Maybe<Scalars['String']>;
  iconName_not?: Maybe<Scalars['String']>;
  iconName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  iconName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  iconName_contains?: Maybe<Scalars['String']>;
  iconName_not_contains?: Maybe<Scalars['String']>;
  action_exists?: Maybe<Scalars['Boolean']>;
  action?: Maybe<Scalars['String']>;
  action_not?: Maybe<Scalars['String']>;
  action_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  action_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  action_contains?: Maybe<Scalars['String']>;
  action_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<GettingStartedStepFilter>>>;
  AND?: Maybe<Array<Maybe<GettingStartedStepFilter>>>;
};

export type GettingStartedStepLinkingCollections = {
  __typename?: 'GettingStartedStepLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  gettingStartedCollection?: Maybe<GettingStartedCollection>;
};


export type GettingStartedStepLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type GettingStartedStepLinkingCollectionsGettingStartedCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum GettingStartedStepOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ColorSchemeAsc = 'colorScheme_ASC',
  ColorSchemeDesc = 'colorScheme_DESC',
  PreviewImageAsc = 'previewImage_ASC',
  PreviewImageDesc = 'previewImage_DESC',
  IconNameAsc = 'iconName_ASC',
  IconNameDesc = 'iconName_DESC',
  ActionAsc = 'action_ASC',
  ActionDesc = 'action_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}


export enum ImageFormat {
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP',
  Avif = 'AVIF'
}

export enum ImageResizeFocus {
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES'
}

export enum ImageResizeStrategy {
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB'
}

export type ImageTransformOptions = {
  /** Desired width in pixels. Defaults to the original image width. */
  width?: Maybe<Scalars['Dimension']>;
  /** Desired height in pixels. Defaults to the original image height. */
  height?: Maybe<Scalars['Dimension']>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: Maybe<Scalars['Quality']>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: Maybe<Scalars['Int']>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: Maybe<ImageResizeStrategy>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: Maybe<ImageResizeFocus>;
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: Maybe<Scalars['HexColor']>;
  /** Desired image format. Defaults to the original image format. */
  format?: Maybe<ImageFormat>;
};



export type Query = {
  __typename?: 'Query';
  asset?: Maybe<Asset>;
  assetCollection?: Maybe<AssetCollection>;
  gettingStartedStep?: Maybe<GettingStartedStep>;
  gettingStartedStepCollection?: Maybe<GettingStartedStepCollection>;
  gettingStarted?: Maybe<GettingStarted>;
  gettingStartedCollection?: Maybe<GettingStartedCollection>;
  tourStep?: Maybe<TourStep>;
  tourStepCollection?: Maybe<TourStepCollection>;
  deck?: Maybe<Deck>;
  deckCollection?: Maybe<DeckCollection>;
  tour?: Maybe<Tour>;
  tourCollection?: Maybe<TourCollection>;
  deckCategory?: Maybe<DeckCategory>;
  deckCategoryCollection?: Maybe<DeckCategoryCollection>;
  deckCollectionCollection?: Maybe<DeckCollectionCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type QueryAssetArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryAssetCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<AssetFilter>;
  order?: Maybe<Array<Maybe<AssetOrder>>>;
};


export type QueryGettingStartedStepArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryGettingStartedStepCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<GettingStartedStepFilter>;
  order?: Maybe<Array<Maybe<GettingStartedStepOrder>>>;
};


export type QueryGettingStartedArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryGettingStartedCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<GettingStartedFilter>;
  order?: Maybe<Array<Maybe<GettingStartedOrder>>>;
};


export type QueryTourStepArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryTourStepCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<TourStepFilter>;
  order?: Maybe<Array<Maybe<TourStepOrder>>>;
};


export type QueryDeckArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryDeckCollectionArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryTourArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryTourCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<TourFilter>;
  order?: Maybe<Array<Maybe<TourOrder>>>;
};


export type QueryDeckCategoryArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryDeckCategoryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<DeckCategoryFilter>;
  order?: Maybe<Array<Maybe<DeckCategoryOrder>>>;
};


export type QueryDeckCollectionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<DeckCollectionFilter>;
  order?: Maybe<Array<Maybe<DeckCollectionOrder>>>;
};


export type QueryEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<EntryFilter>;
  order?: Maybe<Array<Maybe<EntryOrder>>>;
};

export type Sys = {
  __typename?: 'Sys';
  id: Scalars['String'];
  spaceId: Scalars['String'];
  environmentId: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  firstPublishedAt?: Maybe<Scalars['DateTime']>;
  publishedVersion?: Maybe<Scalars['Int']>;
};

export type SysFilter = {
  id_exists?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  id_not?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains?: Maybe<Scalars['String']>;
  id_not_contains?: Maybe<Scalars['String']>;
  publishedAt_exists?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  publishedAt_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_exists?: Maybe<Scalars['Boolean']>;
  firstPublishedAt?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_not?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  firstPublishedAt_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  firstPublishedAt_gt?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_gte?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_lt?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_lte?: Maybe<Scalars['DateTime']>;
  publishedVersion_exists?: Maybe<Scalars['Boolean']>;
  publishedVersion?: Maybe<Scalars['Float']>;
  publishedVersion_not?: Maybe<Scalars['Float']>;
  publishedVersion_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  publishedVersion_not_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  publishedVersion_gt?: Maybe<Scalars['Float']>;
  publishedVersion_gte?: Maybe<Scalars['Float']>;
  publishedVersion_lt?: Maybe<Scalars['Float']>;
  publishedVersion_lte?: Maybe<Scalars['Float']>;
};

/** Container for tour steps [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tour) */
export type Tour = Entry & {
  __typename?: 'Tour';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<TourLinkingCollections>;
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stepsCollection?: Maybe<TourStepsCollection>;
};


/** Container for tour steps [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tour) */
export type TourLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Container for tour steps [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tour) */
export type TourIdArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Container for tour steps [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tour) */
export type TourTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Container for tour steps [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tour) */
export type TourDescriptionArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Container for tour steps [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tour) */
export type TourStepsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type TourCollection = {
  __typename?: 'TourCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Tour>>;
};

export type TourFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  id_exists?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  id_not?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains?: Maybe<Scalars['String']>;
  id_not_contains?: Maybe<Scalars['String']>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  stepsCollection_exists?: Maybe<Scalars['Boolean']>;
  OR?: Maybe<Array<Maybe<TourFilter>>>;
  AND?: Maybe<Array<Maybe<TourFilter>>>;
};

export type TourLinkingCollections = {
  __typename?: 'TourLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type TourLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum TourOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** The steps that make up a tour. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tourStep) */
export type TourStep = Entry & {
  __typename?: 'TourStep';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<TourStepLinkingCollections>;
  headline?: Maybe<Scalars['String']>;
  body?: Maybe<TourStepBody>;
  videoUrl?: Maybe<Scalars['String']>;
  posterUrl?: Maybe<Scalars['String']>;
  staticImageUrl?: Maybe<Scalars['String']>;
};


/** The steps that make up a tour. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tourStep) */
export type TourStepLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** The steps that make up a tour. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tourStep) */
export type TourStepHeadlineArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** The steps that make up a tour. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tourStep) */
export type TourStepBodyArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** The steps that make up a tour. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tourStep) */
export type TourStepVideoUrlArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** The steps that make up a tour. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tourStep) */
export type TourStepPosterUrlArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** The steps that make up a tour. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tourStep) */
export type TourStepStaticImageUrlArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type TourStepBody = {
  __typename?: 'TourStepBody';
  json: Scalars['JSON'];
  links: TourStepBodyLinks;
};

export type TourStepBodyAssets = {
  __typename?: 'TourStepBodyAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type TourStepBodyEntries = {
  __typename?: 'TourStepBodyEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type TourStepBodyLinks = {
  __typename?: 'TourStepBodyLinks';
  entries: TourStepBodyEntries;
  assets: TourStepBodyAssets;
};

export type TourStepCollection = {
  __typename?: 'TourStepCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<TourStep>>;
};

export type TourStepFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  headline_exists?: Maybe<Scalars['Boolean']>;
  headline?: Maybe<Scalars['String']>;
  headline_not?: Maybe<Scalars['String']>;
  headline_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  headline_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  headline_contains?: Maybe<Scalars['String']>;
  headline_not_contains?: Maybe<Scalars['String']>;
  body_exists?: Maybe<Scalars['Boolean']>;
  body_contains?: Maybe<Scalars['String']>;
  body_not_contains?: Maybe<Scalars['String']>;
  videoUrl_exists?: Maybe<Scalars['Boolean']>;
  videoUrl?: Maybe<Scalars['String']>;
  videoUrl_not?: Maybe<Scalars['String']>;
  videoUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  videoUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  videoUrl_contains?: Maybe<Scalars['String']>;
  videoUrl_not_contains?: Maybe<Scalars['String']>;
  posterUrl_exists?: Maybe<Scalars['Boolean']>;
  posterUrl?: Maybe<Scalars['String']>;
  posterUrl_not?: Maybe<Scalars['String']>;
  posterUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  posterUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  posterUrl_contains?: Maybe<Scalars['String']>;
  posterUrl_not_contains?: Maybe<Scalars['String']>;
  staticImageUrl_exists?: Maybe<Scalars['Boolean']>;
  staticImageUrl?: Maybe<Scalars['String']>;
  staticImageUrl_not?: Maybe<Scalars['String']>;
  staticImageUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  staticImageUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  staticImageUrl_contains?: Maybe<Scalars['String']>;
  staticImageUrl_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<TourStepFilter>>>;
  AND?: Maybe<Array<Maybe<TourStepFilter>>>;
};

export type TourStepLinkingCollections = {
  __typename?: 'TourStepLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  tourCollection?: Maybe<TourCollection>;
};


export type TourStepLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type TourStepLinkingCollectionsTourCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum TourStepOrder {
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  VideoUrlAsc = 'videoUrl_ASC',
  VideoUrlDesc = 'videoUrl_DESC',
  PosterUrlAsc = 'posterUrl_ASC',
  PosterUrlDesc = 'posterUrl_DESC',
  StaticImageUrlAsc = 'staticImageUrl_ASC',
  StaticImageUrlDesc = 'staticImageUrl_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type TourStepsCollection = {
  __typename?: 'TourStepsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<TourStep>>;
};

export type CfDeckCollectionNestedFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  id_exists?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  id_not?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains?: Maybe<Scalars['String']>;
  id_not_contains?: Maybe<Scalars['String']>;
  name_exists?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<CfDeckCollectionNestedFilter>>>;
  AND?: Maybe<Array<Maybe<CfDeckCollectionNestedFilter>>>;
};

export type GetCategoriesHighLevelQueryVariables = Exact<{
  where?: Maybe<DeckCategoryFilter>;
}>;


export type GetCategoriesHighLevelQuery = (
  { __typename?: 'Query' }
  & { deckCategoryCollection?: Maybe<(
    { __typename?: 'DeckCategoryCollection' }
    & Pick<DeckCategoryCollection, 'limit' | 'total' | 'skip'>
    & { items: Array<Maybe<(
      { __typename?: 'DeckCategory' }
      & Pick<DeckCategory, 'title'>
      & { description?: Maybe<(
        { __typename?: 'DeckCategoryDescription' }
        & Pick<DeckCategoryDescription, 'json'>
      )>, sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ), parentCollection?: Maybe<(
        { __typename?: 'DeckCollection' }
        & Pick<DeckCollection, 'id'>
      )>, decksCollection?: Maybe<(
        { __typename?: 'DeckCategoryDecksCollection' }
        & Pick<DeckCategoryDecksCollection, 'limit' | 'total' | 'skip'>
        & { items: Array<Maybe<(
          { __typename?: 'Deck' }
          & Pick<Deck, 'title' | 'docId' | 'stagingDocId' | 'previewCardId' | 'isNew' | 'authorDisplayName'>
          & { authorImage?: Maybe<(
            { __typename?: 'Asset' }
            & Pick<Asset, 'url'>
          )>, previewImage?: Maybe<(
            { __typename?: 'Asset' }
            & Pick<Asset, 'url'>
          )> }
        )>> }
      )> }
    )>> }
  )> }
);

export type GetGettingStartedOptionsQueryVariables = Exact<{
  where?: Maybe<GettingStartedFilter>;
}>;


export type GetGettingStartedOptionsQuery = (
  { __typename?: 'Query' }
  & { gettingStartedCollection?: Maybe<(
    { __typename?: 'GettingStartedCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'GettingStarted' }
      & Pick<GettingStarted, 'id'>
      & { optionsCollection?: Maybe<(
        { __typename?: 'GettingStartedOptionsCollection' }
        & Pick<GettingStartedOptionsCollection, 'total'>
        & { items: Array<Maybe<(
          { __typename?: 'GettingStartedStep' }
          & Pick<GettingStartedStep, 'title' | 'colorScheme' | 'previewImage' | 'iconName' | 'action'>
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ), body?: Maybe<(
            { __typename?: 'GettingStartedStepBody' }
            & Pick<GettingStartedStepBody, 'json'>
          )> }
        )>> }
      )> }
    )>> }
  )> }
);

export type GetToursQueryVariables = Exact<{
  where?: Maybe<TourFilter>;
}>;


export type GetToursQuery = (
  { __typename?: 'Query' }
  & { tourCollection?: Maybe<(
    { __typename?: 'TourCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'Tour' }
      & Pick<Tour, 'id' | 'title' | 'description'>
      & { stepsCollection?: Maybe<(
        { __typename?: 'TourStepsCollection' }
        & Pick<TourStepsCollection, 'total'>
        & { items: Array<Maybe<(
          { __typename?: 'TourStep' }
          & Pick<TourStep, 'headline' | 'videoUrl' | 'posterUrl' | 'staticImageUrl'>
          & { body?: Maybe<(
            { __typename?: 'TourStepBody' }
            & Pick<TourStepBody, 'json'>
          )>, sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        )>> }
      )> }
    )>> }
  )> }
);


export const GetCategoriesHighLevelDocument = gql`
    query GetCategoriesHighLevel($where: DeckCategoryFilter) {
  deckCategoryCollection(where: $where, limit: 30, order: priority_DESC) {
    limit
    total
    skip
    items {
      description {
        json
      }
      title
      sys {
        id
      }
      parentCollection {
        id
      }
      decksCollection(preview: false, limit: 30) {
        limit
        total
        skip
        items {
          title
          docId
          stagingDocId
          previewCardId
          isNew
          authorImage {
            url
          }
          authorDisplayName
          previewImage {
            url
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCategoriesHighLevelQuery__
 *
 * To run a query within a React component, call `useGetCategoriesHighLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesHighLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesHighLevelQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCategoriesHighLevelQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoriesHighLevelQuery, GetCategoriesHighLevelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesHighLevelQuery, GetCategoriesHighLevelQueryVariables>(GetCategoriesHighLevelDocument, options);
      }
export function useGetCategoriesHighLevelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesHighLevelQuery, GetCategoriesHighLevelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesHighLevelQuery, GetCategoriesHighLevelQueryVariables>(GetCategoriesHighLevelDocument, options);
        }
export type GetCategoriesHighLevelQueryHookResult = ReturnType<typeof useGetCategoriesHighLevelQuery>;
export type GetCategoriesHighLevelLazyQueryHookResult = ReturnType<typeof useGetCategoriesHighLevelLazyQuery>;
export type GetCategoriesHighLevelQueryResult = Apollo.QueryResult<GetCategoriesHighLevelQuery, GetCategoriesHighLevelQueryVariables>;
export const GetGettingStartedOptionsDocument = gql`
    query GetGettingStartedOptions($where: GettingStartedFilter) {
  gettingStartedCollection(where: $where, limit: 12) {
    items {
      id
      optionsCollection {
        items {
          sys {
            id
          }
          title
          body {
            json
          }
          colorScheme
          previewImage
          iconName
          action
        }
        total
      }
    }
  }
}
    `;

/**
 * __useGetGettingStartedOptionsQuery__
 *
 * To run a query within a React component, call `useGetGettingStartedOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGettingStartedOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGettingStartedOptionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetGettingStartedOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetGettingStartedOptionsQuery, GetGettingStartedOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGettingStartedOptionsQuery, GetGettingStartedOptionsQueryVariables>(GetGettingStartedOptionsDocument, options);
      }
export function useGetGettingStartedOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGettingStartedOptionsQuery, GetGettingStartedOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGettingStartedOptionsQuery, GetGettingStartedOptionsQueryVariables>(GetGettingStartedOptionsDocument, options);
        }
export type GetGettingStartedOptionsQueryHookResult = ReturnType<typeof useGetGettingStartedOptionsQuery>;
export type GetGettingStartedOptionsLazyQueryHookResult = ReturnType<typeof useGetGettingStartedOptionsLazyQuery>;
export type GetGettingStartedOptionsQueryResult = Apollo.QueryResult<GetGettingStartedOptionsQuery, GetGettingStartedOptionsQueryVariables>;
export const GetToursDocument = gql`
    query GetTours($where: TourFilter) {
  tourCollection(where: $where, limit: 1) {
    items {
      id
      title
      description
      stepsCollection {
        items {
          body {
            json
          }
          headline
          sys {
            id
          }
          videoUrl
          posterUrl
          staticImageUrl
        }
        total
      }
    }
  }
}
    `;

/**
 * __useGetToursQuery__
 *
 * To run a query within a React component, call `useGetToursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToursQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetToursQuery(baseOptions?: Apollo.QueryHookOptions<GetToursQuery, GetToursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetToursQuery, GetToursQueryVariables>(GetToursDocument, options);
      }
export function useGetToursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetToursQuery, GetToursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetToursQuery, GetToursQueryVariables>(GetToursDocument, options);
        }
export type GetToursQueryHookResult = ReturnType<typeof useGetToursQuery>;
export type GetToursLazyQueryHookResult = ReturnType<typeof useGetToursLazyQuery>;
export type GetToursQueryResult = Apollo.QueryResult<GetToursQuery, GetToursQueryVariables>;