import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  SlideFade,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'

import { ExistingWorkspace, useUpdateWorkspaceMutation } from 'modules/api'
import { useUserContext } from 'modules/user'
import { WORKSPACE_NAME_MIN_LENGTH } from 'modules/workspaces/constants'

export const GeneralSettings = ({
  workspace,
}: {
  workspace: ExistingWorkspace
}) => {
  const toast = useToast()
  const { refetch: refetchUser } = useUserContext()
  const [name, setName] = useState<string>(workspace?.name || '')
  const [showButtons, setShowButtons] = useState<boolean>(false)
  const [updateWorkspace, { loading, called, error, reset }] =
    useUpdateWorkspaceMutation()

  const onCancel = useCallback(() => {
    setShowButtons(false)
    setName(workspace?.name || '')
  }, [workspace?.name])
  const onSave = useCallback(() => {
    updateWorkspace({
      variables: {
        input: {
          name,
          id: workspace?.id as string,
        },
      },
      fetchPolicy: 'no-cache', // prevent the entire screen from re-rendering
    })
      .then(() => {
        // Refetch the user
        refetchUser?.()
        toast({
          status: 'success',
          title: 'Success!',
          description: 'Workspace updated successfully.',
          position: 'top',
          duration: 5000,
        })
        setTimeout(() => {
          setShowButtons(false)
          reset() // reset the mutation state so that called gets set to false again
        }, 1000)
      })
      .catch((e) => {
        console.error(
          `[WorkspaceSettingsModal] Error updating workspace "${workspace?.id}"`,
          e.message
        )
      })
  }, [name, workspace?.id, refetchUser, reset, toast, updateWorkspace])

  const isError =
    name === '' ||
    name.length < WORKSPACE_NAME_MIN_LENGTH ||
    name.length > 50 ||
    Boolean(error?.message)

  useEffect(() => {
    if (name !== workspace.name) {
      setShowButtons(true)
    }
  }, [name, workspace.name])

  if (!workspace) return null
  return (
    <Box>
      {/* <Heading size="md" fontWeight="600" mb={4}>
        <Box mr={2} display="inline">
          <FontAwesomeIcon icon={regular('gear')} />
        </Box>
        Workspace settings
      </Heading> */}

      <FormControl isInvalid={isError}>
        <FormLabel>Workspace name</FormLabel>
        <Stack w="100%" direction={['column', 'row']}>
          <Input
            w={['100%', '50%']}
            placeholder="Workspace name"
            value={name}
            onChange={(e) => setName(e.target.value.slice(0, 50))}
          />
          <SlideFade in={showButtons} unmountOnExit>
            <Box>
              <ButtonGroup w="100%">
                <Button
                  variant="solid"
                  isLoading={loading}
                  isDisabled={isError || called}
                  onClick={onSave}
                >
                  Update
                </Button>
                <Button onClick={onCancel}>Cancel</Button>
              </ButtonGroup>
            </Box>
          </SlideFade>
        </Stack>
        <FormHelperText>E.g. your team or company name.</FormHelperText>
        <FormErrorMessage>
          {error?.message
            ? error?.message
            : `The name must be at least ${
                WORKSPACE_NAME_MIN_LENGTH > 1
                  ? `${WORKSPACE_NAME_MIN_LENGTH} characters`
                  : `1 character`
              }  long.`}
        </FormErrorMessage>
      </FormControl>
    </Box>
  )
}
