import { DocumentGridItem } from '@gamma-app/ui'
import NextLink from 'next/link'
import { useCallback, useEffect, memo } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import placeholderBackground from 'gamma_components/placeholderBackground.svg'
import { Card, Doc, DocSortField, ExistingWorkspace } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { getTagsForDocExcludingChannel } from 'modules/sharing/utils'
import { GraphqlUser } from 'modules/user'
import {
  ChannelDocDragItem,
  ChannelDocDragItemType,
  FilterByTypes,
} from 'sections/home_v2/types'
import { generateDocUrl } from 'utils/url'

import { getTimeStampAndDescriptor } from '..'

interface DocGridItemWrapperProps {
  user?: GraphqlUser
  currentWorkspace?: ExistingWorkspace
  doc: Doc
  currentChannelId: string | null
  sortBy: DocSortField
  filterBy: FilterByTypes
  canDuplicate: boolean
  canEditDoc: boolean
  canManageDoc: boolean
  createdByYou?: boolean
  cardThumbnails: Pick<Card, 'id' | 'previewUrl' | 'archived'>[]
  fetchThumbnails: (docId: string) => void

  handleDuplicateDoc: (doc: Doc) => void
  handleFavoriteDoc: (date: Date | null, doc: Doc) => () => void
  handleTrash: (docId: string) => void
  handleRestoreDoc: (docId: string) => void
  handleShareClick: (docId: string) => void
  handleRenameClick: (doc: Doc) => void
  handleRemoveFromChannel: (doc: Doc, channelId: string | null) => void
}
export const DocGridItemWrapper = memo(
  ({
    user,
    currentWorkspace,
    doc,
    currentChannelId,
    sortBy,
    filterBy,
    canDuplicate,
    canEditDoc,
    canManageDoc,
    createdByYou,
    cardThumbnails,
    fetchThumbnails,

    handleDuplicateDoc,
    handleFavoriteDoc,
    handleTrash,
    handleRestoreDoc,
    handleShareClick,
    handleRenameClick,
    handleRemoveFromChannel,
  }: DocGridItemWrapperProps) => {
    const { id, title, createdTime, archived, titleCard, docUser, createdBy } =
      doc
    const {
      timestamp,
      descriptor,
    }: {
      timestamp: string
      descriptor: string | undefined
    } = getTimeStampAndDescriptor({ sortBy, docUser, filterBy, doc })
    const screenshotsEnabled = useFeatureFlag('screenshotsEnabled')
    const tags = getTagsForDocExcludingChannel({
      doc,
      user,
      currentWorkspace,
      channelToExclude: currentChannelId,
    })
    const thumbnailImageUrl = {
      src: screenshotsEnabled
        ? titleCard?.previewUrl
        : placeholderBackground.src,
      fallbackSrc: placeholderBackground.src,
    }
    const isShareEnabled = canEditDoc
    const isRenameEnabled = canEditDoc
    const isRemoveFromChannelEnabled = Boolean(currentChannelId)

    const [_, drag, dragPreview] = useDrag(
      {
        type: ChannelDocDragItemType,
        item: (): ChannelDocDragItem => doc,
        options: {
          dropEffect: 'copy',
          // Super hack! This forces the new doc dependency to recompute
          // the draggable state whenever the doc changes
          // See https://github.com/react-dnd/react-dnd/issues/3345
          // @ts-ignore
          doc,
        },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      },
      [canManageDoc, doc]
    )

    useEffect(() => {
      dragPreview(getEmptyImage())
    }, [dragPreview])
    const onMouseEnter = useCallback(() => {
      // Disable until we can fix
      // fetchThumbnails(doc.id)
    }, [doc.id, fetchThumbnails])

    return (
      <DocumentGridItem
        ref={drag}
        id={id}
        key={id}
        title={title || ''}
        thumbnailImageUrl={thumbnailImageUrl}
        NextLink={NextLink}
        href={generateDocUrl({ docId: id })}
        createdByYou={createdByYou}
        createdTime={createdTime}
        timestamp={timestamp}
        timestampDescriptor={descriptor}
        createdByName={createdBy?.displayName || ''}
        createdByProfileImageUrl={createdBy?.profileImageUrl}
        createdByEmail={createdBy?.email || ''}
        isDuplicateEnabled={canDuplicate}
        isShareEnabled={isShareEnabled}
        isRenameEnabled={isRenameEnabled}
        isTrashAndRestoreEnabled={canManageDoc}
        isTrashed={archived}
        isFavorited={!!docUser?.favorited}
        isRemoveFromChannelEnabled={isRemoveFromChannelEnabled}
        onDuplicate={() => handleDuplicateDoc(doc)}
        onFavorite={handleFavoriteDoc(new Date(), doc)}
        onUnfavorite={handleFavoriteDoc(null, doc)}
        onTrash={() => handleTrash(doc.id)}
        onRestore={() => handleRestoreDoc(doc.id)}
        onShareClick={() => handleShareClick(doc.id)}
        onRename={() => handleRenameClick(doc)}
        onRemoveFromChannel={() =>
          handleRemoveFromChannel(doc, currentChannelId)
        }
        tags={tags}
        menuEnabled={true}
        cardThumbnails={[]}
        onMouseEnter={onMouseEnter}
      />
    )
  }
)

DocGridItemWrapper.displayName = 'DocGridItemWrapper'
