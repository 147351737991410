import { useToast } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { useCreateDocMutation } from 'modules/api'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { emptyDoc, SCHEMA_VERSION } from 'modules/tiptap_editor'
import {
  htmlToYDocSnapshot,
  jsonToYDocSnapshot,
} from 'modules/tiptap_editor/utils/transform'
import { useUserContext } from 'modules/user'

type CreateDocAndNavigateParams = {
  html?: string
  channelId?: string | null
  title?: string
}

export const useCreateDocAndNavigate = () => {
  const { user, currentWorkspace } = useUserContext()

  const analytics = useAnalytics()

  const [createDoc, { data, loading }] = useCreateDocMutation()

  const { push } = useRouter()

  const toast = useToast()

  const createDocAndNavigate = useCallback(
    (params?: CreateDocAndNavigateParams) => {
      const html = params?.html
      const channelId = params?.channelId
      if (!currentWorkspace?.id) {
        console.error('[createDocAndNavigate] No currentWorkspace found')
        return
      }
      const document = emptyDoc(user)
      const encodedDocument = html
        ? htmlToYDocSnapshot(html)
        : jsonToYDocSnapshot(document)

      console.debug('[createDocAndNavigate] Creating doc', {
        document,
        encodedDocument,
      })

      return createDoc({
        variables: {
          workspaceId: currentWorkspace?.id,
          title: params?.title || '',
          initialContent: {
            ydoc: encodedDocument,
            schemaVersion: SCHEMA_VERSION,
            editors: [user?.id!],
          },
          ...(channelId ? { channels: [{ channelId }] } : {}),
        },
      })
        .then(async ({ data: createData }) => {
          analytics?.track(SegmentEvents.DOC_CREATED, {
            doc_id: createData?.createDoc.id,
            source: html ? 'import' : 'blank',
            channel: channelId ? channelId : null,
          })
          console.debug(
            '[Header] Doc created. Navigating to Editor',
            createData?.createDoc.id
          )
          push(`/docs/${createData?.createDoc.id}`)
        })
        .catch((error) => {
          console.error('[createDocAndNavigate]', error)
          toast({
            title: 'Error creating document',
            description: error.message,
            position: 'top',
            status: 'error',
            isClosable: true,
            duration: null,
          })
        })
    },
    [analytics, createDoc, currentWorkspace, push, toast, user]
  )

  return [createDocAndNavigate, { data, loading }] as const
}
