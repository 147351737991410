import {
  Box,
  Grid,
  GridItem,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react'

import { ViewPreferenceTypes } from '../../types'

const SKELETON_GRID_ITEM_COUNT = 2
const SKELETON_GRID_MAP = new Array(SKELETON_GRID_ITEM_COUNT).fill(1)
const SKELETON_LIST_ITEM_COUNT = 4
const SKELETON_LIST_MAP = new Array(SKELETON_LIST_ITEM_COUNT).fill(1)

export const DocsViewSkeleton = ({
  viewPreference = 'grid',
}: {
  viewPreference?: ViewPreferenceTypes
}) => {
  if (viewPreference === 'grid') {
    return (
      <Box width="100%" data-testid="docs-view-skeleton">
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
            xl: 'repeat(4, 1fr)',
            '2xl': 'repeat(5, 1fr)',
          }}
          gap={6}
        >
          {SKELETON_GRID_MAP.map((_, index) => {
            return (
              <GridItem
                key={index}
                bg="linen.50"
                borderRadius="5px"
                height="240px"
                as={Skeleton}
              />
            )
          })}
        </Grid>
      </Box>
    )
  }

  return (
    <Box width="100%" data-testid="docs-view-skeleton">
      <TableContainer pt={4} maxW="100%">
        <Table
          colorScheme="blackAlpha"
          size="sm"
          whiteSpace={'normal'}
          variant="simple"
        >
          <Tbody>
            {SKELETON_LIST_MAP.map((_, index) => {
              return (
                <Tr key={index}>
                  <Td as={Skeleton} height="36px" mb={2} />
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}
