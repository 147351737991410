import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useClipboard,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CHANNEL_DISPLAY_NAME } from '@gamma-app/ui'
import { useRouter } from 'next/router'
import React from 'react'

import { Channel } from 'modules/api'
import { useChannelMutations } from 'sections/home_v2/hooks/useChannelMutations'
import { generateChannelUrl } from 'utils/url'

import { DeleteChannelModal } from './DeleteChannelModal'
import { RenameChannelModal } from './RenameChannelModal'

export const ManageChannelMenu = ({
  channel,
  workspaceId,
}: {
  channel: Channel
  workspaceId?: string
}) => {
  const {
    isOpen: isDeleteChannelModalOpen,
    onOpen: onDeleteChannelModalOpen,
    onClose: onDeleteChannelModalClose,
  } = useDisclosure({ id: 'deleteChannelModalDisclosure' })
  const {
    isOpen: isRenameChannelModalOpen,
    onOpen: onRenameChannelModalOpen,
    onClose: onRenameChannelModalClose,
  } = useDisclosure({ id: 'renameChannelModalDisclosure' })
  const { push } = useRouter()
  const { leaveChannel } = useChannelMutations()
  const toast = useToast()
  const { onCopy } = useClipboard(
    generateChannelUrl({ id: channel?.id, slug: channel?.slug }, false)
  )

  return (
    <Menu closeOnSelect={false} isLazy={true}>
      <MenuButton
        as={IconButton}
        variant="ghost"
        fontWeight="600"
        color="gray.700"
        icon={<FontAwesomeIcon icon={regular('ellipsis')} />}
      />
      <MenuList zIndex="popover">
        <MenuItem
          icon={<FontAwesomeIcon fixedWidth icon={regular('pen-field')} />}
          onClick={onRenameChannelModalOpen}
        >
          Rename {CHANNEL_DISPLAY_NAME}
        </MenuItem>
        <MenuDivider />
        <MenuItem
          onClick={() => {
            onCopy()
            toast({
              title: 'URL copied.',
              status: 'success',
              duration: 3000,
              position: 'top',
            })
          }}
          icon={
            <FontAwesomeIcon fixedWidth icon={regular('share-from-square')} />
          }
        >
          Copy {CHANNEL_DISPLAY_NAME} link
        </MenuItem>

        <MenuItem
          icon={<FontAwesomeIcon fixedWidth icon={regular('person-to-door')} />}
          onClick={() => {
            leaveChannel({ channel }).then(() => {
              push('/')
            })
          }}
        >
          Leave {CHANNEL_DISPLAY_NAME}
        </MenuItem>
        <MenuDivider />
        <MenuItem
          color="red.500"
          onClick={onDeleteChannelModalOpen}
          icon={<FontAwesomeIcon fixedWidth icon={regular('trash')} />}
        >
          Delete {CHANNEL_DISPLAY_NAME}
        </MenuItem>
      </MenuList>

      {isRenameChannelModalOpen && (
        <RenameChannelModal
          channel={channel}
          workspaceId={workspaceId}
          onClose={onRenameChannelModalClose}
        />
      )}
      {isDeleteChannelModalOpen && (
        <DeleteChannelModal
          channel={channel}
          workspaceId={workspaceId}
          onClose={onDeleteChannelModalClose}
        />
      )}
    </Menu>
  )
}
