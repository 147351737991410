import { Box } from '@chakra-ui/react'
import { memo } from 'react'

import { Channel, DocActivity, DocSortField, SortDirection } from 'modules/api'
import { useUserContext } from 'modules/user'
import { FilterByTypes, ViewPreferenceTypes } from 'sections/home_v2/types'
import { isMobileDevice } from 'utils/deviceDetection'

import { DocsTabs, SidebarTabsType, TabMap } from '../../Sidebar'
import { ChannelDocActivity } from './ChannelDocActivity'
import { Header } from './Header'
import { ViewControls } from './ViewControls'

export const DocsViewTopbar = memo(
  ({
    activeTab,
    channel,
    isChannelPreview,
    activeDocs,

    sortDirection,
    sortBy,
    filterBy,
    viewPreference,
    setSortBy,
    setSortDirection,

    setViewPreference,
    setFilterBy,
  }: {
    activeTab: SidebarTabsType
    channel?: Channel
    isChannelPreview: boolean
    activeDocs?: DocActivity[]

    setSortBy: (sortBy: DocSortField) => void
    setSortDirection: (sortDirection: SortDirection) => void
    sortBy: DocSortField
    sortDirection: SortDirection
    filterBy: FilterByTypes
    viewPreference: ViewPreferenceTypes

    setViewPreference: (viewPreference: ViewPreferenceTypes) => void
    setFilterBy: (filterBy: FilterByTypes) => void
  }) => {
    const shouldShowSortingUIs =
      !isMobileDevice &&
      activeTab !== DocsTabs.EXAMPLES &&
      activeTab !== DocsTabs.TEMPLATES

    const shouldShowCategoryFilters = Boolean(
      activeTab === DocsTabs.ALL || channel
    )

    const { currentWorkspace } = useUserContext()
    const { displayName, description, icon, showCreateButton } = {
      ...TabMap[activeTab],
      ...{
        displayName: channel ? channel.name : TabMap[activeTab].displayName,
      },
    }
    return (
      <Box w="100%" mb={6}>
        <Header
          displayName={displayName}
          description={description}
          icon={icon}
          showCreateButton={
            !isMobileDevice && showCreateButton && !isChannelPreview
          }
          currentWorkspace={currentWorkspace}
          channel={channel}
          isChannelPreview={isChannelPreview}
        />
        {activeDocs && activeDocs.length > 0 && (
          <ChannelDocActivity activeDocs={activeDocs} filterBy={filterBy} />
        )}

        <ViewControls
          filterBy={filterBy}
          sortBy={sortBy}
          sortDirection={sortDirection}
          setViewPreference={setViewPreference}
          setFilterBy={setFilterBy}
          setSortBy={setSortBy}
          setSortDirection={setSortDirection}
          viewPreference={viewPreference}
          shouldShowCategoryFilters={shouldShowCategoryFilters}
          currentChannelId={channel?.id || null}
          shouldShowSortOptions={
            shouldShowSortingUIs && viewPreference === 'grid'
          }
          shouldShowViewToggle={shouldShowSortingUIs}
        />
      </Box>
    )
  }
)

DocsViewTopbar.displayName = 'DocsViewTopbar'
