import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Skeleton,
} from '@chakra-ui/react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import {
  ExistingWorkspace,
  SortDirection,
  useGetWorkspaceMembersQuery,
  WorkspaceMembersSortField,
} from 'modules/api'

const MEMBERS_PAGE_SIZE = 50

export const MembersList = ({
  workspace,
  dateFormatterFn,
}: {
  workspace: ExistingWorkspace
  dateFormatterFn: (date: string) => string
}) => {
  const [sortField, setSortField] = useState<WorkspaceMembersSortField>(
    WorkspaceMembersSortField.DisplayName
  )
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Asc
  )
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false)

  const variables = {
    id: workspace?.id,
    first: MEMBERS_PAGE_SIZE,
    sortBy: {
      field: sortField,
      direction: sortDirection,
    },
  }
  const { data, loading, fetchMore } = useGetWorkspaceMembersQuery({
    variables,
    skip: !workspace?.id,
  })

  const pageInfo = data?.workspaceMembers?.pageInfo
  const showPaginationControls =
    !loading && pageInfo?.hasNextPage && pageInfo?.endCursor
  const members = data?.workspaceMembers.edges.map((edge) => edge.node)

  return (
    <>
      <TableContainer>
        <Table
          variant="simple"
          colorScheme="blackAlpha"
          size="sm"
          whiteSpace="normal"
        >
          <Thead>
            <Tr>
              <Th>
                <Button
                  ml={-3}
                  size="sm"
                  color="gray.500"
                  variant="ghost"
                  colorScheme="blackAlpha"
                  aria-label="sort"
                  onClick={() => {
                    setSortField(WorkspaceMembersSortField.DisplayName)
                    setSortDirection((prev) =>
                      prev === SortDirection.Asc
                        ? SortDirection.Desc
                        : SortDirection.Asc
                    )
                  }}
                  rightIcon={
                    <Box
                      color={
                        sortField === WorkspaceMembersSortField.DisplayName
                          ? 'gray.700'
                          : 'gray.300'
                      }
                    >
                      <FontAwesomeIcon
                        icon={
                          sortField === WorkspaceMembersSortField.DisplayName
                            ? sortDirection === 'asc'
                              ? solid('caret-down')
                              : solid('caret-up')
                            : solid('sort')
                        }
                      />
                    </Box>
                  }
                >
                  Name
                </Button>
              </Th>
              <Th>
                <Button
                  ml={-3}
                  size="sm"
                  color="gray.500"
                  variant="ghost"
                  colorScheme="blackAlpha"
                  aria-label="sort"
                  onClick={() => {
                    setSortField(WorkspaceMembersSortField.CreatedTime)
                    setSortDirection((prev) =>
                      prev === SortDirection.Asc
                        ? SortDirection.Desc
                        : SortDirection.Asc
                    )
                  }}
                  rightIcon={
                    <Box
                      color={
                        sortField === WorkspaceMembersSortField.CreatedTime
                          ? 'gray.700'
                          : 'gray.300'
                      }
                    >
                      <FontAwesomeIcon
                        icon={
                          sortField === WorkspaceMembersSortField.CreatedTime
                            ? sortDirection === 'asc'
                              ? solid('caret-down')
                              : solid('caret-up')
                            : solid('sort')
                        }
                      />
                    </Box>
                  }
                >
                  Join date
                </Button>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading && (
              <Tr p={0}>
                <Td colSpan={2} p={0}>
                  <Stack spacing={2}>
                    <Skeleton h={8} />
                    <Skeleton h={8} />
                    <Skeleton h={8} />
                  </Stack>
                </Td>
              </Tr>
            )}

            {members &&
              members?.length > 0 &&
              members.map((member, i) => {
                const isLastRow = i === members.length - 1
                const formattedCreatedTime = dateFormatterFn(member.createdTime)
                return (
                  <Tr key={member.id}>
                    <Td borderBottom={isLastRow ? '0 none' : undefined}>
                      <UserAvatarBlock user={member} />
                    </Td>
                    <Td
                      borderBottom={isLastRow ? '0 none' : undefined}
                      whiteSpace="nowrap"
                    >
                      {formattedCreatedTime}
                    </Td>
                  </Tr>
                )
              })}
          </Tbody>
        </Table>
      </TableContainer>
      {showPaginationControls && (
        <Flex w="100%" justify="center" align="center" mt={3}>
          <Button
            isLoading={isFetchingMore}
            size="sm"
            onClick={() => {
              setIsFetchingMore(true)
              fetchMore({
                variables: {
                  after: pageInfo?.endCursor,
                },
              }).finally(() => {
                setIsFetchingMore(false)
              })
            }}
          >
            Load more
          </Button>
        </Flex>
      )}
    </>
  )
}

const UserAvatarBlock = ({ user }) => {
  const { displayName, profileImageUrl, email } = user
  return (
    <HStack>
      <Avatar size="sm" name={displayName} src={profileImageUrl} />
      <Stack spacing={0}>
        <Text>{displayName}</Text>
        <HStack mt={1}>
          <Text fontSize="xs" color="gray.500">
            {email}
          </Text>
        </HStack>
      </Stack>
    </HStack>
  )
}
