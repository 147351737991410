import {
  Button,
  Divider,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Link,
  SimpleGrid,
  useBreakpointValue,
  Text,
  Skeleton,
  Stack,
  Box,
  HStack,
  VStack,
} from '@chakra-ui/react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { IconName } from '@fortawesome/fontawesome-common-types'
import {
  duotone,
  regular,
} from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DOC_DISPLAY_NAME, DOC_DISPLAY_NAME_PLURAL } from '@gamma-app/ui'
import NextLink from 'next/link'
import Router, { useRouter } from 'next/router'
import { useCallback } from 'react'

import { config } from 'config'
import { DocSortField, SortDirection, useGetDocsQuery } from 'modules/api'
import { useCreateDocAndNavigate } from 'modules/api/hooks'
import { useGetGettingStartedOptionsQuery } from 'modules/contentful'
import { replaceState } from 'modules/history'
import { useUserContext } from 'modules/user'
import { isMobileDevice } from 'utils/deviceDetection'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import { GetStartedOption } from './GetStartedOption'

const iconMap: Partial<Record<IconName, React.ReactElement>> = {
  // Fontawesome types dont allow size="xl" even though it appears to be a valid size
  bolt: <FontAwesomeIcon icon={duotone('bolt')} className="fa-xl" />,
  'rectangle-history': (
    <FontAwesomeIcon icon={duotone('rectangle-history')} className="fa-xl" />
  ),
  'book-open': (
    <FontAwesomeIcon icon={duotone('book-open')} className="fa-xl" />
  ),
}

const docToReactOptions = {
  renderMark: {
    [MARKS.UNDERLINE]: function HighlightNode(text) {
      return (
        <Text as="span" className="highlight">
          {text}
        </Text>
      )
    },
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: function TextNode(node, children) {
      return <Text fontSize="sm">{children}</Text>
    },
  },
}

export const GetStartedModal = () => {
  const { push, query, pathname } = useRouter()
  const [createDoc] = useCreateDocAndNavigate()
  const hasWelcomeQueryParam = Boolean(query.welcome)
  const handleOnCloseClick = useCallback(
    (newPath?: string) => {
      const { searchParams } = new URL(window.location.href)
      searchParams.delete('welcome')

      if (!newPath) {
        // User closed or clicked outside - Clear the query param and trigger a next route
        Router.replace(
          { pathname, query: searchParams.toString() },
          undefined,
          {
            shallow: true,
          }
        )
      } else if (newPath.startsWith('/#')) {
        // User chose a sidebar tab - Router.replace with the full new path
        Router.replace(newPath)
      } else {
        // User is navigating outside of the home screen - clear the param then do a push
        replaceState({ query: Object.fromEntries(searchParams.entries()) })
        push(newPath)
      }
    },
    [push, pathname]
  )

  const handleCreateDocClick = useCallback(() => {
    const { searchParams } = new URL(window.location.href)
    searchParams.delete('welcome')
    replaceState({ query: Object.fromEntries(searchParams.entries()) })
    createDoc()
  }, [createDoc])

  if (!hasWelcomeQueryParam) return null

  return (
    <GetStartedModalContent
      onClose={handleOnCloseClick}
      onCreateDoc={handleCreateDocClick}
    />
  )
}

const GetStartedModalContent = ({
  onClose,
  onCreateDoc,
}: {
  onClose: (pathname?: string) => void
  onCreateDoc: () => void
}) => {
  const [welcomeDocIdLS] = useLocalStorage<string | null>(
    USER_SETTINGS_CONSTANTS.welcomeDocId,
    null
  )
  const size = useBreakpointValue(['sm', 'md', '3xl', '4xl'])
  const { user, currentWorkspace } = useUserContext()
  const userId = user?.id

  const {
    data: welcomeDocData,
    loading: welcomeDocLoading,
    called: welcomeDocCalled,
  } = useGetDocsQuery({
    variables: {
      workspaceId: currentWorkspace?.id,
      first: 1,
      createdBy: userId,
      sortBy: {
        field: DocSortField.CreatedTime,
        direction: SortDirection.Asc,
      },
    },
    skip: Boolean(!userId || welcomeDocIdLS),
  })

  const welcomeDocId = welcomeDocIdLS || welcomeDocData?.docs.edges[0]?.node.id

  const { data, loading } = useGetGettingStartedOptionsQuery({
    variables: {
      where: {
        id: 'gettingStarted',
      },
    },
    context: {
      clientName: 'contentfulGraphql',
    },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  })

  const handleActionClick = useCallback(
    (action?: string) => {
      switch (action) {
        case 'welcomeDoc':
          if (welcomeDocId) {
            onClose(`/docs/${welcomeDocId}`)
          } else {
            console.warn('[StartingModal] No welcomeDoc')
          }
          break

        case 'templates':
          onClose('/#templates')
          break

        case 'inspiration':
          onClose('/#inspiration')
          break

        default:
          console.warn('[StartingModal] Unknown action', action)
          break
      }
    },
    [onClose, welcomeDocId]
  )

  const collection = data?.gettingStartedCollection?.items || []
  const options = collection[0]?.optionsCollection?.items
  const shouldShowSkeleton = loading || !data || !options

  return (
    <Modal isOpen={true} size={size} trapFocus={false} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>How would you like to get started?</ModalHeader>
        <ModalCloseButton />
        {isMobileDevice ? (
          <Box padding="4" background="salmon.100" mb="4">
            <HStack spacing="5" paddingLeft="2">
              <Box color="salmon.600">
                <FontAwesomeIcon icon={regular('desktop')} className="fa-xl" />
              </Box>
              <VStack align="flex-start" spacing="1">
                <Text fontWeight="600" fontSize="md">
                  Create {DOC_DISPLAY_NAME_PLURAL} on desktop
                </Text>
                <Text fontSize="sm">
                  Heads up! Gamma {DOC_DISPLAY_NAME_PLURAL} are view/comment
                  only on mobile. Jump on a computer or tablet to create your
                  first {DOC_DISPLAY_NAME}.
                </Text>
              </VStack>
            </HStack>
          </Box>
        ) : (
          <Divider mb="4" />
        )}
        <ModalBody>
          <SimpleGrid columns={[1, 1, 3]} spacing={5}>
            {shouldShowSkeleton ? (
              <GettingStartedSkeleton />
            ) : options ? (
              options.map((option) => {
                const {
                  sys,
                  iconName,
                  colorScheme,
                  previewImage,
                  title,
                  body,
                  action,
                } = option || {}
                if (!action || !title || !body?.json || !sys?.id) return null

                const isMissingWelcomeDoc =
                  !welcomeDocIdLS && // We didnt find an id in local storage from signing up
                  welcomeDocCalled && // We've kicked off the fetch for the users first doc
                  !welcomeDocLoading && // The fetch isnt loading anymore
                  !welcomeDocData // And finally there is no data from the fetch
                if (
                  action === 'welcomeDoc' &&
                  // Dont show the welcomeDoc option if we cant find a welcome doc. This is unexpected
                  isMissingWelcomeDoc
                ) {
                  console.error(
                    '[GetStartedModalContent] Unexpectedly cant find a welcomeDocId'
                  )
                  return null
                }

                // Dont show templates on mobile since creation & editing are disabled
                if (action === 'templates' && isMobileDevice) return null

                const href =
                  action === 'templates'
                    ? '/#templates'
                    : action === 'inspiration'
                    ? '/#inspiration'
                    : action === 'welcomeDoc'
                    ? `/docs/${welcomeDocId}`
                    : '/'

                return (
                  <GetStartedOption
                    key={sys.id}
                    accentColor={colorScheme || 'trueblue'}
                    title={title}
                    body={documentToReactComponents(
                      body.json,
                      docToReactOptions
                    )}
                    href={href}
                    bgImage={previewImage || config.OG_IMAGE_URL}
                    icon={iconMap[iconName || 'rectangle-history']}
                    onClick={(e) => {
                      if (!e.metaKey) {
                        e.preventDefault()
                        handleActionClick(action)
                      }
                    }}
                  />
                )
              })
            ) : null}
          </SimpleGrid>
        </ModalBody>
        <ModalFooter>
          {!isMobileDevice && (
            <Stack alignItems="center" minWidth="100%">
              <Text>
                You can also start in a{' '}
                <NextLink href="/new" passHref>
                  <Button
                    as={Link}
                    colorScheme="black"
                    variant="link"
                    textDecoration="underline"
                    mb="4"
                    onClick={(e) => {
                      if (!e.metaKey) {
                        e.preventDefault()
                        onCreateDoc()
                      }
                    }}
                  >
                    blank {DOC_DISPLAY_NAME}
                  </Button>
                </NextLink>
              </Text>
            </Stack>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const GettingStartedSkeleton = () => {
  return (
    <>
      <GridItem>
        <Skeleton height={['100px', '100px', '320px']} />
      </GridItem>
      <GridItem>
        <Skeleton height={['100px', '100px', '320px']} />
      </GridItem>
      <GridItem>
        <Skeleton height={['100px', '100px', '320px']} />
      </GridItem>
    </>
  )
}
