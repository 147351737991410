import {
  Avatar,
  Box,
  Button,
  HStack,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Wrap,
} from '@chakra-ui/react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CHANNEL_DISPLAY_NAME_PLURAL,
  docTitleOrPlaceholder,
  DocumentGridItemMenu,
  DOC_DISPLAY_NAME,
  FavoriteButton,
  LinkTagGroup,
} from '@gamma-app/ui'
import { formatDistanceStrict, parseISO } from 'date-fns'
import capitalize from 'lodash/capitalize'
import NextLink from 'next/link'

import {
  Doc,
  DocSortField,
  ExistingWorkspace,
  SortDirection,
} from 'modules/api'
import {
  getTagsForDocExcludingChannel,
  isDocPrivate,
} from 'modules/sharing/utils'
import { GraphqlUser, useAbility } from 'modules/user'
import {
  DISPLAY_DATE_OPTIONS,
  DOC_USER_DISPLAY_DATE_OPTIONS,
} from 'sections/home_v2/constants'
import { DateDisplayOptionType, FilterByTypes } from 'sections/home_v2/types'
import { isDocUserFilter } from 'sections/home_v2/utils'
import { generateDocUrl } from 'utils/url'

import { DateDisplayOptions } from './DateDisplayOptions'
import { DocsTableRow } from './DocsTableRow'

const TITLE_MIN_WIDTH = '280px'
const getDateColumnHeaderConfig = (
  filterBy: FilterByTypes,
  dateDisplayOption: DateDisplayOptionType
) => {
  const _isDocUserFilter = isDocUserFilter(filterBy)
  // Returns the list of items to show in the date column menu.
  // For "All", "Created by you" and "Private", we can use `doc.createdTime`, `doc.editedTime`.
  // For "Recent" and "Favorites", we can only use fields on `doc.docUser`.
  const displayOptions = _isDocUserFilter //
    ? DOC_USER_DISPLAY_DATE_OPTIONS
    : DISPLAY_DATE_OPTIONS

  // We can only change the sort order of the date field in some circumstances.
  const showSortOrderToggle =
    !_isDocUserFilter ||
    (filterBy === 'recent' && dateDisplayOption === DocSortField.LastViewed) ||
    (filterBy === 'favorites' && dateDisplayOption === DocSortField.Favorited)

  // We can't sort by title yet at the API level if we are using a docUser filter.
  const enableTitleSort = !_isDocUserFilter

  return { displayOptions, showSortOrderToggle, enableTitleSort }
}

type DocsTableProps = {
  user?: GraphqlUser
  currentWorkspace?: ExistingWorkspace
  userHasNoWorkspaces: boolean
  docs: Doc[]
  currentChannelId: string | null
  dateDisplayOption: DateDisplayOptionType
  sortBy: DocSortField
  sortDirection: SortDirection
  setDateDisplayOption: (dateDisplayOption: DateDisplayOptionType) => void
  setSortBy: (sortBy: DocSortField) => void
  setSortDirection: (sortDirection: SortDirection) => void
  filterBy: FilterByTypes

  handleDuplicateDoc: (doc: Doc) => void
  handleFavoriteDoc: (date: Date | null, doc: Doc) => () => void
  handleTrash: (docId: string) => void
  handleRestoreDoc: (docId: string) => void
  handleShareClick: (docId: string) => void
  handleRenameClick: (doc: Doc) => void
  handleRemoveFromChannel: (doc: Doc, channelId: string | null) => void
}
export const DocsTable = ({
  user,
  currentWorkspace,
  userHasNoWorkspaces,
  docs,
  currentChannelId,
  dateDisplayOption,
  sortBy,
  sortDirection,
  setDateDisplayOption,
  setSortBy,
  setSortDirection,
  filterBy,

  handleDuplicateDoc,
  handleFavoriteDoc,
  handleTrash,
  handleRestoreDoc,
  handleShareClick,
  handleRenameClick,
  handleRemoveFromChannel,
}: DocsTableProps) => {
  const ability = useAbility()

  const { displayOptions, showSortOrderToggle, enableTitleSort } =
    getDateColumnHeaderConfig(filterBy, dateDisplayOption)
  return (
    <TableContainer pt={4} maxW="100%">
      <Table
        colorScheme="blackAlpha"
        size="sm"
        whiteSpace="normal"
        variant="simple"
      >
        <Thead>
          <Tr color="gray.800">
            <Th w={4} />
            <Th justifyContent="space-between" minW={TITLE_MIN_WIDTH}>
              {enableTitleSort && (
                <Button
                  ml={-3}
                  size="sm"
                  color="gray.500"
                  variant="ghost"
                  colorScheme="blackAlpha"
                  aria-label="sort"
                  onClick={() => {
                    setSortBy(DocSortField.Title)
                    setSortDirection(
                      sortDirection === SortDirection.Asc
                        ? SortDirection.Desc
                        : SortDirection.Asc
                    )
                  }}
                  rightIcon={
                    <Box
                      color={
                        sortBy === DocSortField.Title ? 'gray.700' : 'gray.300'
                      }
                    >
                      <FontAwesomeIcon
                        icon={
                          sortBy === DocSortField.Title
                            ? sortDirection === SortDirection.Asc
                              ? solid('caret-down')
                              : solid('caret-up')
                            : solid('sort')
                        }
                      />
                    </Box>
                  }
                >
                  {capitalize(DOC_DISPLAY_NAME)}
                </Button>
              )}
              {!enableTitleSort && capitalize(DOC_DISPLAY_NAME)}
            </Th>
            <Th>{capitalize(CHANNEL_DISPLAY_NAME_PLURAL)}</Th>
            <Th w={32} pr={-2}>
              <DateDisplayOptions
                size="sm"
                sortBy={sortBy}
                sortDirection={sortDirection}
                setSortBy={setSortBy}
                setSortDirection={setSortDirection}
                options={displayOptions}
                showSortOrderToggle={showSortOrderToggle}
                exclude={[DocSortField.Title]}
                dateDisplayOption={dateDisplayOption}
                setDateDisplayOption={setDateDisplayOption}
              />
            </Th>
            <Th w={32}>Creator</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {docs?.map((doc: Doc) => {
            const canDuplicate =
              ability.can('view', doc) && !userHasNoWorkspaces
            const canManageDoc =
              ability.can('manage', doc) && !userHasNoWorkspaces
            const canEditDoc = ability.can('edit', doc) && !userHasNoWorkspaces
            const tags = getTagsForDocExcludingChannel({
              doc,
              user,
              currentWorkspace,
              channelToExclude: currentChannelId,
            })
            const isShareEnabled = canEditDoc
            const isRemoveFromChannelEnabled = Boolean(currentChannelId)
            return (
              <DocsTableRow
                canManageDoc={canManageDoc}
                user={user}
                key={doc.id}
                tags={tags}
                doc={doc}
                dateDisplayOption={dateDisplayOption}
                handleFavoriteDoc={handleFavoriteDoc}
                handleShareClick={handleShareClick}
                menu={
                  <DocumentGridItemMenu
                    href={generateDocUrl({ docId: doc.id })}
                    title={docTitleOrPlaceholder(doc.title)}
                    createdByName={doc?.createdBy?.displayName || ''}
                    createdByEmail={doc?.createdBy?.email || ''}
                    createdTime={doc.createdTime}
                    isDuplicateEnabled={canDuplicate}
                    isTrashAndRestoreEnabled={canManageDoc}
                    isRenameEnabled={canEditDoc}
                    isRemoveFromChannelEnabled={isRemoveFromChannelEnabled}
                    isShareEnabled={isShareEnabled}
                    isTrashed={Boolean(doc?.archived)}
                    isFavorited={doc.docUser?.favorited}
                    onDuplicate={() => handleDuplicateDoc(doc)}
                    onFavorite={handleFavoriteDoc(new Date(), doc)}
                    onUnfavorite={handleFavoriteDoc(null, doc)}
                    onTrash={() => handleTrash(doc.id)}
                    onRestore={() => handleRestoreDoc(doc.id)}
                    onShareClick={() => handleShareClick(doc.id)}
                    onRename={() => handleRenameClick(doc)}
                    onRemoveFromChannel={() =>
                      handleRemoveFromChannel(doc, currentChannelId)
                    }
                  />
                }
              />
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
