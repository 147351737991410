import {
  Box,
  Button,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useToast,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  DOC_DISPLAY_NAME,
  GammaTooltip,
  LoadingToastInner,
} from '@gamma-app/ui'
import { hasGrantedAllScopesGoogle, useGoogleLogin } from '@react-oauth/google'
import { useCallback, useState } from 'react'

import { config } from 'config'
import { GoogleDriveProvider } from 'modules/media'
import { useUserContext } from 'modules/user'
import { useScript } from 'utils/hooks'

const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js'

// Space delimited set of scopes
// This gets us access to the files in the drive that they select through the picker
// This is basically the only non-sensitive drive scope, so change this at your peril
const scope = 'https://www.googleapis.com/auth/drive.file'

export const GooglePicker = ({
  onChange,
  onAuthFailed,
  disabled,
  createBlank,
}) => {
  const { user } = useUserContext()
  const [pickerReady, setPickerReady] = useState(false)
  const [accessToken, setAccessToken] = useState<string>()
  const toast = useToast()
  const loadDoc = useCallback(
    (token, fileId, mimeType, title) => {
      // https://developers.google.com/drive/api/v3/reference/files/export
      const url = `https://www.googleapis.com/drive/v3/files/${fileId}/export?mimeType=${mimeType}`

      const loadDocToast = toast({
        id: `load-doc-toast-${fileId}`,
        position: 'top',
        duration: null,
        render: function LoadingToast({ onClose, id }) {
          return (
            <LoadingToastInner
              title="Importing..."
              description="This should only take a moment"
              isClosable={false}
              onClose={onClose}
              id={id}
            />
          )
        },
      }) as string
      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.text())
        .then((html) => {
          onChange(html, title)
          toast.update(loadDocToast, {
            status: 'success',
            title: 'Success!',
            duration: 5000,
          })
        })
        .catch((error) => {
          console.log(`Error importing doc ${fileId} from google`, error)
          return
        })
    },
    [onChange, toast]
  )

  const onSelect = useCallback(
    (token, data) => {
      if (data.action !== 'picked') return

      const [doc] = data.docs
      if (doc.mimeType === 'application/vnd.google-apps.document') {
        loadDoc(token, doc.id, 'text/html', doc.name)
      } else if (doc.mimeType === 'application/vnd.google-apps.presentation') {
        loadDoc(token, doc.id, 'text/plain', doc.name)
      } else {
        // toast maybe?
      }
    },
    [loadDoc]
  )

  const showPicker = useCallback(
    (token) => {
      setAccessToken(token)

      // @ts-ignore
      const google = window.google

      const docsView = new google.picker.DocsView(google.picker.ViewId.DOCS)
        .setIncludeFolders(true)
        .setMimeTypes([
          // Google MIME Types: https://developers.google.com/drive/api/guides/mime-types
          'application/vnd.google-apps.document',
          'application/vnd.google-apps.folder',
          // 'application/vnd.google-apps.presentation',
        ])

      const picker = new google.picker.PickerBuilder()
        .addView(docsView)
        // .addView(google.picker.ViewId.PRESENTATIONS)
        .setOAuthToken(token)
        .setAppId(config.GOOGLE_PROJECT_ID)
        .setDeveloperKey(config.GOOGLE_API_KEY)
        .setCallback((data) => {
          onSelect(token, data)
        })

      picker.build().setVisible(true)

      // the picker modal has a weird z-index, so bring it to the top
      Array.from(document.getElementsByClassName('picker-dialog')).forEach(
        (element) => {
          // @ts-ignore
          element.style.zIndex = '2000'
        }
      )
    },
    [onSelect]
  )

  const postPickerLoad = useCallback(() => {
    // @ts-ignore
    window.gapi.load('picker', () => {
      setPickerReady(true)
    })
  }, [])

  useScript(GOOGLE_SDK_URL, postPickerLoad)

  const googleLogin = useGoogleLogin({
    scope,
    hint: user?.email,
    prompt: '',
    onSuccess: (response) => {
      if (hasGrantedAllScopesGoogle(response, scope)) {
        showPicker(response.access_token)
      } else {
        onAuthFailed('Could not get requested scopes')
      }
    },
    onError: onAuthFailed,
  })

  const onChoose = () => {
    if (!pickerReady || disabled) return

    if (!accessToken) {
      googleLogin()
    } else {
      showPicker(accessToken)
    }
  }

  return (
    <Menu>
      <GammaTooltip
        label="Quickly turn walls of text into readable decks you can share or present"
        placement="top"
      >
        <MenuButton
          as={Button}
          // Prevents tooltip from opening on focus, see https://github.com/chakra-ui/chakra-ui/issues/5304#issuecomment-1102836734
          onFocus={(e) => e.preventDefault()}
          data-testid="import-doc-button"
          disabled={disabled}
          leftIcon={<FontAwesomeIcon icon={regular('arrow-down-to-square')} />}
          rightIcon={<FontAwesomeIcon icon={regular('angle-down')} />}
          sx={{
            span: {
              // Keeps the icons from getting pushed to the edges on mobile
              flexGrow: 0,
            },
          }}
        >
          Import
        </MenuButton>
      </GammaTooltip>
      <Portal>
        <MenuList maxW="300px" zIndex="dropdown">
          <MenuItem
            icon={<Image h="1.5em" src={GoogleDriveProvider.image?.src} />}
            onClick={onChoose}
          >
            Google Doc
          </MenuItem>
          <MenuDivider />
          <Box px={4} py={0}>
            <Text fontSize="sm" color="gray.500">
              Tip: You can also paste content from most writing tools directly
              into a{' '}
              <Button variant="link" onClick={createBlank} size="sm">
                blank {DOC_DISPLAY_NAME}
              </Button>{' '}
              and we'll split it into cards automatically.
            </Text>
          </Box>
        </MenuList>
      </Portal>
    </Menu>
  )
}
