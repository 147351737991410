import flatten from 'lodash/flatten'

import { canSafelyIgnoreErrors } from 'modules/contentful'
import { useGetCategoriesHighLevelQuery } from 'modules/contentful/generated/graphql'

import { getSimplifiedCategories } from '../utils'
export const useGetExampleDecks = (
  collectionId: 'inspiration' | 'templates'
) => {
  const {
    data: exampleCategoriesData,
    loading,
    error,
  } = useGetCategoriesHighLevelQuery({
    variables: {
      where: {
        parentCollection: {
          id: collectionId,
        },
      },
    },
    context: {
      clientName: 'contentfulGraphql',
    },
    onError: (err) => {
      if (!canSafelyIgnoreErrors(err)) {
        console.error('[useGetExampleDecks] error', err.message)
      }
    },
    // Both data and error.graphQLErrors are populated, enabling you to render both partial results and error information.
    // https://www.apollographql.com/docs/react/data/error-handling#graphql-error-policies
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  })

  const categories = getSimplifiedCategories(
    exampleCategoriesData?.deckCategoryCollection?.items
  )
  // List of decks, flattened
  const decks = flatten(
    exampleCategoriesData?.deckCategoryCollection?.items.map((cat) => {
      return cat?.decksCollection?.items
    })
  )

  const sanitizedError = canSafelyIgnoreErrors(error) ? undefined : error

  return {
    categories,
    decks,
    loading,
    error: sanitizedError,
  }
}
