import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { FilterByOptionsMap, FilterByTypes } from '../../../types'

export const FILTER_BY_OPTIONS: FilterByOptionsMap = {
  all: {
    label: 'All',
    icon: regular('rectangle-history'),
  },
  recent: {
    label: 'Recently viewed',
    icon: regular('clock'),
  },
  private: {
    label: 'Private',
    icon: regular('lock'),
  },
  createdByYou: {
    label: 'Created by you',
    icon: regular('user-circle'),
  },
  favorites: {
    label: 'Favorites',
    icon: regular('star'),
  },
}
const FILTER_BY_OPTIONS_ORDERED_KEYS: FilterByTypes[] = [
  'all',
  'recent',
  'createdByYou',
  'private',
  'favorites',
]

export const FILTER_BY_OPTIONS_ORDERED = FILTER_BY_OPTIONS_ORDERED_KEYS.map(
  (key) => {
    return {
      key,
      ...FILTER_BY_OPTIONS[key],
    }
  }
)

export const FILTER_BY_OPTIONS_ORDERED_KEYS__CHANNEL_VIEW: FilterByTypes[] = [
  'all',
  'recent',
  'createdByYou',
  'favorites',
]

export const FILTER_BY_OPTIONS_ORDERED__CHANNEL_VIEW =
  FILTER_BY_OPTIONS_ORDERED_KEYS__CHANNEL_VIEW.map((key) => {
    return {
      key,
      ...FILTER_BY_OPTIONS[key],
    }
  })
