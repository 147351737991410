import { InternalRefetchQueriesInclude } from '@apollo/client'
import { Link, Text, useToast } from '@chakra-ui/react'
import { DOC_DISPLAY_NAME } from '@gamma-app/ui'
import { capitalize } from 'lodash'
import { useCallback } from 'react'

import {
  Doc,
  useArchiveDocMutation,
  useFavoriteDocMutation,
  useUnArchiveDocMutation,
  DocUserUpdateFragmentDoc,
} from 'modules/api'
import { useUserContext } from 'modules/user'

export const useDocMutations = ({
  refetchQueries,
}: {
  refetchQueries?: InternalRefetchQueriesInclude | undefined
}) => {
  const { user } = useUserContext()
  const toast = useToast()

  const [_archiveDoc] = useArchiveDocMutation({
    refetchQueries,
  })
  const [_unarchiveDoc] = useUnArchiveDocMutation({
    refetchQueries,
  })
  const [_favoriteDoc] = useFavoriteDocMutation({
    refetchQueries,
  })

  const favoriteDoc = useCallback(
    (favorited: Date | null, doc: Doc) => {
      return () => {
        if (!user || !doc) return
        const variables = {
          input: {
            docId: doc.id,
            userId: user?.id as string,
            favorited,
          },
        }
        _favoriteDoc({
          variables,
          update: (cache, { data }) => {
            cache.writeFragment({
              id: `Doc:${doc.id}`,
              fragment: DocUserUpdateFragmentDoc,
              data: {
                docUser: data?.updateDocUser,
              },
            })
          },
          optimisticResponse: {
            updateDocUser: {
              ...doc.docUser!,
              favorited: variables.input.favorited,
              __typename: 'DocUser',
            },
          },
        })
      }
    },
    [_favoriteDoc, user]
  )

  const restoreDoc = useCallback(
    (id: string) => {
      const variables = { id }
      _unarchiveDoc({
        variables,
        optimisticResponse: {
          unarchiveDoc: {
            ...variables,
            archived: false,
            __typename: 'Doc',
          },
        },
      }).then(() => {
        toast({
          title: `${capitalize(
            DOC_DISPLAY_NAME
          )} restored. You can now edit it.`,
          status: 'success',
          duration: 10000,
          position: 'top',
        })
      })
    },
    [_unarchiveDoc, toast]
  )

  const trashDoc = useCallback(
    (id: string) => {
      const variables = { id }
      _archiveDoc({
        variables,
        optimisticResponse: {
          archiveDoc: {
            ...variables,
            archived: true,
            __typename: 'Doc',
          },
        },
      }).then(() => {
        toast({
          title: (
            <Text>
              {capitalize(DOC_DISPLAY_NAME)} moved to trash.{' '}
              <Link textDecoration="underline" onClick={() => restoreDoc(id)}>
                Undo
              </Link>
            </Text>
          ),
          status: 'success',
          duration: 3000,
          position: 'top',
        })
      })
    },
    [_archiveDoc, restoreDoc, toast]
  )
  return { favoriteDoc, restoreDoc, trashDoc }
}
