import {
  Box,
  Flex,
  IconButton,
  Skeleton,
  Spacer,
  Stack,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Logo } from 'gamma_components'
import { WorkspaceSwitcherSkeleton } from 'modules/workspaces'

export const SidebarSkeleton = ({ setIsSidebarVisible }) => {
  return (
    <>
      <Stack pt={2}>
        <WorkspaceSwitcherSkeleton />
        <Box
          display={{
            sm: 'lg',
            md: 'none',
          }}
        >
          <IconButton
            aria-label="Close Sidebar"
            size="sm"
            variant="ghost"
            icon={<FontAwesomeIcon icon={regular('times')} />}
            onClick={() => setIsSidebarVisible(false)}
          />
        </Box>
        <Skeleton w="100%" height="36px" />
      </Stack>
      <Skeleton w="100%" height="30px" mt={4} />

      <Skeleton w="100%" height="20px" mt={8} />
      <Skeleton w="100%" height="30px" mt={4} />
      <Skeleton w="100%" height="30px" mt={2} />
      <Skeleton w="100%" height="30px" mt={2} />
      <Spacer />
      <Flex justify="center" align="flex-end">
        <Box w="30px">{Logo}</Box>
      </Flex>
    </>
  )
}
