import {
  Box,
  Divider,
  Flex,
  IconButton,
  Skeleton,
  SkeletonText,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction } from 'react'

import { ProfileMenu } from 'gamma_components'
import { User } from 'modules/api'
import { useUserContext } from 'modules/user'

type TopbarWrapperProps = {
  isSidebarVisible: boolean
  setIsSidebarVisible: Dispatch<SetStateAction<boolean>>
  user?: User
  children?: React.ReactNode
}

export const TopbarSkeleton = () => {
  return (
    <Box w="80%" py={6}>
      <Skeleton height="30px" w="30%" mb={2} />
      <SkeletonText noOfLines={2} />
    </Box>
  )
}

export const TopbarWrapper = ({
  setIsSidebarVisible,
  isSidebarVisible,
  children,
}: TopbarWrapperProps) => {
  const { currentWorkspace } = useUserContext()

  return (
    <Box w="100%" mb={6} maxW="100%">
      {/* This is the header that's displayed on small screens. */}
      <Box
        w="100%"
        display={{
          sm: 'block',
          md: 'none',
        }}
        mb={4}
      >
        <Flex align="center" w="100%" mb={2} justify="space-between">
          <IconButton
            aria-label="Open Sidebar"
            variant="ghost"
            icon={<FontAwesomeIcon icon={regular('bars')} />}
            size="sm"
            mr={2}
            ml={-2}
            onClick={() => setIsSidebarVisible(!isSidebarVisible)}
            isRound
          />
          <Flex
            flex="1"
            w="100%"
            justify="space-between"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            <Text
              fontSize="lg"
              fontWeight="600"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {currentWorkspace?.name}
            </Text>
            <Spacer />
            <ProfileMenu />
          </Flex>
        </Flex>
        <Divider />
      </Box>
      {children}
    </Box>
  )
}
