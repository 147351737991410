import {
  Box,
  Heading,
  Text,
  useBreakpointValue,
  Badge,
  Button,
  CloseButton,
  Collapse,
  Flex,
  Link,
  LinkBox,
  LinkOverlay,
  Stack,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DocumentGrid } from '@gamma-app/ui'
import NextLink from 'next/link'
import { Dispatch, SetStateAction } from 'react'

import { useFeatureFlag } from 'modules/featureFlags'
import { ExampleMemoJSON } from 'modules/featureFlags/flags'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { preventDefaultToAvoidBlur } from 'utils/handlers'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { generateExampleUrl } from 'utils/url'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import { SidebarTabs, TabMap } from '../Sidebar'
import { TopbarWrapper } from '../Topbar'

type DocTemplateItemProps = ExampleMemoJSON

export const DocTemplateItem = ({
  thumbnailUrl,
  docId,
  title,
  isNew = false,
}: DocTemplateItemProps) => {
  const url = generateExampleUrl({ docId })
  return (
    <LinkBox onMouseDown={preventDefaultToAvoidBlur}>
      <NextLink href={url}>
        <LinkOverlay href={url}>
          <Stack>
            <Box
              tabIndex={0}
              role="group"
              borderRadius="5px"
              shadow="md"
              _focus={{
                outline: 'none',
                shadow: 'outline',
              }}
              _focusWithin={{
                shadow: 'outline',
              }}
              _hover={{
                shadow: 'lg',
              }}
              position="relative"
              overflow="hidden"
              transition="box-shadow .2s ease"
              minH={40}
              backgroundRepeat="no-repeat"
              backgroundImage={`url(${thumbnailUrl})`}
              backgroundSize="cover, cover"
              backgroundPosition="left, center"
              color="white"
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                position="absolute"
                inset={0}
                zIndex={2}
              >
                <Button
                  size="sm"
                  variant="solid"
                  transitionProperty="common"
                  transitionDuration="normal"
                  transform="scale(.9)"
                  opacity={0}
                  _groupFocus={{
                    opacity: 1,
                    transform: 'scale(1)',
                  }}
                  _groupHover={{
                    opacity: 1,
                    transform: 'scale(1)',
                  }}
                >
                  View Example
                </Button>
              </Flex>
              {isNew && (
                <Badge
                  position="absolute"
                  bottom={5}
                  right={5}
                  colorScheme="green"
                >
                  New
                </Badge>
              )}
              <Box
                position="absolute"
                inset={0}
                bg="blackAlpha.300"
                zIndex={1}
                transitionProperty="common"
                transitionDuration="normal"
                opacity={0}
                _groupFocus={{
                  opacity: 1,
                }}
                _groupHover={{
                  opacity: 1,
                }}
              ></Box>
            </Box>
            <Text fontSize="md">{title}</Text>
          </Stack>
        </LinkOverlay>
      </NextLink>
    </LinkBox>
  )
}

export const ExamplesViewSnippet = () => {
  const [hasClosedExamples, setHasClosedExamples] = useLocalStorage<boolean>(
    USER_SETTINGS_CONSTANTS.hasClosedExamplesOnRecentTab,
    false
  )
  const analytics = useAnalytics()

  const onClose = () => {
    setHasClosedExamples(true)
    analytics?.track(SegmentEvents.EXAMPLES_SNIPPET_CLOSED)
  }

  return (
    <Collapse in={!hasClosedExamples} unmountOnExit>
      <Box
        data-testid="examples-snippet"
        mb={8}
        bg="linen.100"
        px={6}
        pt={3}
        pb={6}
        borderRadius="xl"
        borderWidth="1px"
        pos="relative"
      >
        <CloseButton
          borderRadius="full"
          opacity={0.5}
          position="absolute"
          right={3}
          top={3}
          onClick={onClose}
        />
        <Box mt={2} mb={4} w="100%">
          <Box fontSize="md" display="inline" mr={2}>
            <FontAwesomeIcon icon={TabMap[SidebarTabs.EXAMPLES].icon} />
          </Box>
          <Text fontSize="lg" fontWeight="600" display="inline">
            Explore Gamma Examples
          </Text>
          <NextLink href={'/#examples'} passHref>
            <Button
              as={Link}
              onClick={() => {
                analytics?.track(SegmentEvents.EXAMPLES_SNIPPET_EXPANDED)
              }}
              variant="link"
              size="md"
              fontSize="md"
              ml={4}
            >
              See all &rarr;
            </Button>
          </NextLink>
        </Box>
        <ExamplesGrid maxRows={1} />
      </Box>
    </Collapse>
  )
}

export const ExamplesGrid = ({ maxRows }: { maxRows?: number }) => {
  const EXAMPLE_MEMOS = useFeatureFlag('exampleMemos').filter(
    (e) => !e.disabled
  )
  // Should match DocumentGrid.tsx
  const rowSize =
    useBreakpointValue({
      base: 1,
      sm: 2,
      lg: 3,
      xl: 4,
      '2xl': 5,
    }) || 3

  const exampleGridItems = maxRows
    ? EXAMPLE_MEMOS.slice(0, rowSize * maxRows)
    : EXAMPLE_MEMOS

  return (
    <Box w="100%" data-testid="examples-view-container">
      <DocumentGrid>
        {exampleGridItems.map(
          ({ docId, thumbnailUrl, title, isNew = false, disabled = false }) => {
            if (disabled) return null
            return (
              <DocTemplateItem
                key={docId}
                title={title}
                docId={docId}
                thumbnailUrl={thumbnailUrl}
                isNew={isNew}
              />
            )
          }
        )}
      </DocumentGrid>
    </Box>
  )
}
export const ExamplesView = ({
  isSidebarVisible,
  setIsSidebarVisible,
}: {
  isSidebarVisible: boolean
  setIsSidebarVisible: Dispatch<SetStateAction<boolean>>
}) => {
  const { displayName, description, icon } = TabMap[SidebarTabs.EXAMPLES]

  return (
    <Box w="100%" data-testid="examples-view-container">
      <TopbarWrapper
        isSidebarVisible={isSidebarVisible}
        setIsSidebarVisible={setIsSidebarVisible}
      >
        <Heading fontSize="lg" fontWeight="600" data-testid="docs-view-header">
          {icon && <FontAwesomeIcon icon={icon} aria-label={displayName} />}{' '}
          <Text as="span" ml={2}>
            {displayName}
          </Text>
        </Heading>
      </TopbarWrapper>
      <ExamplesGrid />
    </Box>
  )
}
