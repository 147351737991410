import { Button, ButtonProps, Link } from '@chakra-ui/react'
import React, { MouseEvent, MutableRefObject } from 'react'

interface SidebarButtonProps extends ButtonProps {
  onClick: (e: MouseEvent) => void
  dataTestId: string
  isSelected?: boolean
  isDropActive?: boolean
  ref?: MutableRefObject<HTMLButtonElement>
}

export const SidebarButton = React.forwardRef(
  (
    {
      children,
      onClick,
      dataTestId,
      isSelected,
      isDropActive,
      ...props
    }: SidebarButtonProps,
    ref: MutableRefObject<HTMLButtonElement>
  ) => {
    return (
      <Button
        ref={ref}
        textDecoration="none"
        variant="ghost"
        w="100%"
        size="sm"
        colorScheme="gray"
        as={Link}
        borderRadius="md"
        onClick={onClick}
        justifyContent="flex-start"
        data-testid={dataTestId}
        _hover={{
          bgColor: isSelected ? 'trueblue.50' : 'gray.100',
          textDecoration: 'none',
        }}
        color={isSelected || isDropActive ? 'trueblue.500' : 'gray.600'}
        bgColor={isSelected || isDropActive ? 'trueblue.50' : undefined}
        _active={{
          color: 'trueblue.500',
          bgColor: 'trueblue.200',
          textDecoration: 'none',
        }}
        py={1.5}
        mb={1}
        paddingInlineStart={2}
        fontWeight={isSelected ? 'bold' : 'regular'}
        {...props}
      >
        {children}
      </Button>
    )
  }
)

SidebarButton.displayName = 'SidebarButton'
