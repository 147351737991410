import { DocSortField } from 'modules/api'

import { SidebarTabs } from './components/Sidebar'
import { FilterByTypes, SortByOptions } from './types'

export const DEFAULT_FILTERBY_TYPE: FilterByTypes = 'recent'
export const DISPLAY_DATE_OPTIONS: SortByOptions = [
  {
    key: DocSortField.EditedTime,
    label: 'Last edited',
  },
  {
    key: DocSortField.CreatedTime,
    label: 'Date created',
  },
]

export const DOC_USER_DISPLAY_DATE_OPTIONS: SortByOptions = [
  {
    key: DocSortField.LastViewed,
    label: 'Last viewed',
  },
  {
    key: DocSortField.LastEdited,
    label: 'Last edited',
  },
  {
    key: DocSortField.Favorited,
    label: 'Date favorited',
  },
]

// In the absence of a tab hash, we'll default to this tab.
export const INITIAL_TAB = SidebarTabs.ALL

export const DEFAULT_DATE_DISPLAY_OPTION = DocSortField.EditedTime

export const DOC_USER_SORTBY_FIELDS = [
  DocSortField.LastViewed,
  DocSortField.LastEdited,
  DocSortField.Favorited,
]
