import { CheckIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Portal,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'

import { DocSortField, SortDirection } from 'modules/api'
import { DateDisplayOptionType, SortByOptions } from 'sections/home_v2/types'

type DateDisplayOptionsProps = {
  sortBy: DocSortField
  sortDirection: SortDirection
  setSortBy: (sortBy: DocSortField) => void
  setSortDirection: (sortDirection: SortDirection) => void
  size?: 'sm' | 'xs'
  exclude?: DocSortField[]
  dateDisplayOption: DateDisplayOptionType
  setDateDisplayOption: (dateDisplayOption: DateDisplayOptionType) => void
  options: SortByOptions
  showSortOrderToggle: boolean
}
export const DateDisplayOptions = ({
  sortBy,
  sortDirection,
  setSortBy,
  setSortDirection,
  size = 'sm',
  dateDisplayOption,
  setDateDisplayOption,
  options,
  showSortOrderToggle,
}: DateDisplayOptionsProps) => {
  return (
    <ButtonGroup variant="ghost" colorScheme="blackAlpha" size={size} ml={-3}>
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          variant="ghost"
          size={size}
          rightIcon={
            <FontAwesomeIcon
              icon={regular('chevron-down')}
              aria-label="Open menu"
            />
          }
          fontWeight="600"
          color="gray.500"
        >
          {options.find((c) => c.key === dateDisplayOption)?.label || 'Date'}
        </MenuButton>
        <Portal>
          <MenuList minWidth="240px">
            <MenuOptionGroup
              fontSize="sm"
              defaultValue="alpha"
              title="Display date"
              type="radio"
            >
              {options.map((option) => {
                const isSelected = dateDisplayOption === option.key
                return (
                  <MenuItem
                    key={option.key}
                    icon={isSelected ? <CheckIcon /> : <Box w={3} />}
                    value={option.key}
                    onClick={() => {
                      setDateDisplayOption(option.key as DateDisplayOptionType)
                      setSortBy(option.key)
                      setSortDirection(SortDirection.Desc)
                    }}
                  >
                    {option.label}
                  </MenuItem>
                )
              })}
            </MenuOptionGroup>
          </MenuList>
        </Portal>
      </Menu>
      {showSortOrderToggle && (
        <GammaTooltip label="Reverse sort order">
          <IconButton
            color={sortBy !== 'title' ? 'gray.700' : 'gray.300'}
            aria-label="sort"
            onClick={() => {
              if (sortBy !== DocSortField.Title) {
                setSortDirection(
                  sortDirection === SortDirection.Asc
                    ? SortDirection.Desc
                    : SortDirection.Asc
                )
              } else {
                setSortDirection(SortDirection.Desc)
              }
              setSortBy(dateDisplayOption)
            }}
            icon={<FontAwesomeIcon icon={regular('arrow-up-arrow-down')} />}
          />
        </GammaTooltip>
      )}
    </ButtonGroup>
  )
}
