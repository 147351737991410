import { HStack, Spacer, Stack } from '@chakra-ui/react'

import { DocSortField, SortDirection } from 'modules/api'

import { FilterByTypes, ViewPreferenceTypes } from '../../../types'

import { CategoryFilters, SortOptions, ViewToggle } from './index'

// Contains Tabs, SorterDropdown, and Grid/Tile Toggle

type ViewControlsProps = {
  currentChannelId: string | null
  filterBy: FilterByTypes
  sortBy: DocSortField
  sortDirection: SortDirection
  viewPreference: ViewPreferenceTypes
  setSortBy: (sortBy: DocSortField) => void
  setSortDirection: (sortDirection: SortDirection) => void
  setViewPreference: (viewPreference: ViewPreferenceTypes) => void
  setFilterBy: (filterBy: FilterByTypes) => void

  shouldShowCategoryFilters: boolean
  shouldShowSortOptions: boolean
  shouldShowViewToggle: boolean
}
// Let's make this dumber.
// Make the following props: shouldShowCategoryFilters, shouldShowSortOptions, shouldShowViewToggle
// Rename this to ViewControls
export const ViewControls = ({
  currentChannelId,
  filterBy,
  sortBy,
  sortDirection,
  viewPreference,
  setFilterBy,
  setSortBy,
  setSortDirection,
  setViewPreference,

  shouldShowCategoryFilters,
  shouldShowSortOptions,
  shouldShowViewToggle,
}: ViewControlsProps) => {
  if (
    !(
      shouldShowCategoryFilters ||
      shouldShowSortOptions ||
      shouldShowViewToggle
    )
  ) {
    return <></>
  }
  return (
    <Stack direction={'row'} alignItems="center">
      {shouldShowCategoryFilters && (
        <CategoryFilters
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          currentChannelId={currentChannelId}
        />
      )}
      <Spacer />
      <HStack alignItems="center">
        {shouldShowSortOptions && (
          <SortOptions
            filterBy={filterBy}
            sortBy={sortBy}
            sortDirection={sortDirection}
            setSortBy={setSortBy}
            setSortDirection={setSortDirection}
          />
        )}
        {shouldShowViewToggle && (
          <ViewToggle
            viewPreference={viewPreference}
            setViewPreference={setViewPreference}
          />
        )}
      </HStack>
    </Stack>
  )
}
