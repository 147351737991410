import { Box, Center, Flex, Heading, Link, Text } from '@chakra-ui/react'
import {
  CHANNEL_DISPLAY_NAME,
  CHANNEL_DISPLAY_NAME_PLURAL,
} from '@gamma-app/ui'
import NextImage from 'next/image'

export const ChannelsEmptyState = ({
  channelName,
  onCreateClick,
}: {
  channelName: string
  onCreateClick: () => void
}) => {
  return (
    <Center minH="sm">
      <Flex
        direction="column"
        justify={'center'}
        align="center"
        w={['100%', '80%']}
      >
        <Box w="80%" maxW="250px" mb={6} alignContent="center">
          <NextImage
            src="/images/Sal-Fishing-2x.png"
            width="640px"
            height="361px"
            alt="Sal, the Gamma mascot, fishing extraterrestrially"
          />
        </Box>
        <Heading size="md" mb={2} textAlign="center">
          No {CHANNEL_DISPLAY_NAME_PLURAL} found.
        </Heading>
        <Text fontSize="lg" color="gray.400" textAlign="center">
          Try changing your search query, or{' '}
          <Link onClick={onCreateClick} color="trueblue.500">
            create a new {CHANNEL_DISPLAY_NAME} called "{channelName}".
          </Link>
        </Text>
      </Flex>
    </Center>
  )
}
