import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuOptionGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  Skeleton,
  SkeletonCircle,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { DOC_DISPLAY_NAME_PLURAL, GammaTooltip } from '@gamma-app/ui'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isNil } from 'lodash'

import { config } from 'config'
import { useUserContext } from 'modules/user'
import { useHealthCheck } from 'modules/api'

export const WorkspaceSwitcherSkeleton = () => {
  return (
    <HStack w="100%" px={2} pb={1}>
      <SkeletonCircle size="32px" />
      <Skeleton width="60%" height="20px" />
    </HStack>
  )
}
const WorkspaceRow = ({
  name,
  memberCount,
  isMultiline = true,
}: {
  name: string
  memberCount?: number | null
  isMultiline?: boolean
}) => {
  return (
    <HStack mr={4}>
      <Avatar
        name={name.replace(/\s/g, '')} // Remove spaces so that we only display one initial
        size="sm"
        bgColor="orchid.300"
        color="white"
      />
      <VStack
        spacing={0}
        alignItems="flex-start"
        {...(!isMultiline
          ? {
              noOfLines: 1,
            }
          : {})}
      >
        <Text
          fontSize="sm"
          fontWeight="600"
          wordBreak="break-word"
          m={0}
          p={0}
          {...(!isMultiline
            ? {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }
            : {})}
          minWidth="0px"
          textAlign="left"
        >
          {name}
        </Text>
        {!isNil(memberCount) && (
          <Text fontSize="xs" color="gray.500" textAlign="left">
            {memberCount === 1 ? '1 member' : `${memberCount} members`}
          </Text>
        )}
      </VStack>
    </HStack>
  )
}

export const WorkspaceSwitcher = ({ onOpen }: { onOpen: () => void }) => {
  const { user, currentWorkspace, setCurrentWorkspaceId } = useUserContext()
  const { isConnected } = useHealthCheck()

  if (!user) return <></>

  const workspaces = user?.organizations
  if (!workspaces) return <></>

  return (
    <>
      <Menu>
        <GammaTooltip
          label={isConnected ? '' : "You're offline"}
          aria-label={isConnected ? 'Workspace options' : "You're offline"}
        >
          <Box>
            <MenuButton
              as={Button}
              disabled={!isConnected}
              variant="ghost"
              rightIcon={
                isConnected ? (
                  <FontAwesomeIcon
                    icon={regular('angle-down')}
                    aria-label="Caret icon"
                  />
                ) : (
                  <FontAwesomeIcon icon={regular('cloud-slash')} />
                )
              }
              w="100%"
              colorScheme="blackAlpha"
              color="gray.800"
              cursor="pointer"
              _hover={{ color: 'trueblue.600' }}
              p={2}
              pr={4}
            >
              <Box width="100%">
                {currentWorkspace && (
                  <WorkspaceRow
                    name={currentWorkspace.name}
                    memberCount={null}
                    isMultiline={false}
                  />
                )}
              </Box>
            </MenuButton>
          </Box>
        </GammaTooltip>
        <MenuList minW="400px" zIndex="overlay" shadow="lg" maxW="400px">
          <HStack w="100%" mx={2}>
            <Avatar src={user?.profileImageUrl} size="sm" />
            <Stack spacing={0}>
              <Text fontSize="sm">{user?.displayName}</Text>
              <Text fontSize="xs" color="gray.500">
                {user?.email}
              </Text>
            </Stack>
          </HStack>
          <MenuDivider />

          {workspaces && workspaces.length > 0 && (
            <MenuOptionGroup
              title="Workspaces"
              type="radio"
              defaultValue={currentWorkspace?.id}
              onChange={(id: string) => {
                setCurrentWorkspaceId(id)
              }}
            >
              {workspaces
                .filter(
                  (org) => !isNil(org) && !isNil(org.id) && !isNil(org.name)
                )
                .map((org) => {
                  return (
                    <MenuItemOption
                      key={org.id}
                      value={org.id}
                      icon={workspaces.length === 1 ? null : undefined}
                      isFocusable={workspaces.length === 1 ? false : true}
                    >
                      <HStack>
                        <Flex flex={1} w="100%">
                          <WorkspaceRow
                            name={org.name}
                            memberCount={null}
                            isMultiline={true}
                          />
                        </Flex>
                        {org.id === currentWorkspace?.id && (
                          <Flex>
                            <Button
                              size="sm"
                              variant="outline"
                              onClick={onOpen}
                            >
                              Settings &amp; members
                            </Button>
                          </Flex>
                        )}
                      </HStack>
                    </MenuItemOption>
                  )
                })}
            </MenuOptionGroup>
          )}
          <MenuDivider />
          <MenuItem
            color="red.500"
            icon={<FontAwesomeIcon icon={regular('sign-out-alt')} fixedWidth />}
            onClick={() => {
              window.location.href = `${config.API_HOST}/logout`
            }}
          >
            Sign out
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}
