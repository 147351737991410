import { Flex, Heading, Stack, Text } from '@chakra-ui/react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

import { SimplifiedCategory } from '../../types'

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: function TextNode(node, children) {
      return <Text color="gray.600">{children}</Text>
    },
  },
}

export const DeckCategoryHeader = ({
  category,
}: {
  category: SimplifiedCategory
}) => {
  const descriptionToRender = category.description
    ? documentToReactComponents(category.description, options)
    : null
  return (
    <Flex direction="row" alignItems={'flex-start'} justify="space-between">
      <Stack mb={6} fontSize="md">
        <Heading fontSize="2xl" mb={-1}>
          {category.title}
        </Heading>
        {descriptionToRender && descriptionToRender}
      </Stack>
    </Flex>
  )
}
