import { useState, useEffect, useCallback, useMemo } from 'react'

import { useGetCardPreviewUrlsForDocLazyQuery } from 'modules/api'
import { isMobileDevice } from 'utils/deviceDetection'

export const useFetchThumbnails = () => {
  const [docId, setDocId] = useState<string | null>(null)

  const [getThumbs, { data: thumbnailsData }] =
    useGetCardPreviewUrlsForDocLazyQuery({
      variables: {
        docId: docId as string,
        maxDepth: 1,
        archived: false,
      },
      returnPartialData: true,
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-only',
    })

  useEffect(() => {
    if (!docId || isMobileDevice) return
    getThumbs({
      variables: {
        docId,
        maxDepth: 1,
      },
    })
  }, [docId, getThumbs])

  // memo-ize for perf
  const emptyThumbnails = useMemo(() => [], [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cardThumbnails = useMemo(() => {
    const thumbs =
      thumbnailsData?.docCards !== undefined &&
      thumbnailsData?.docCards[0]?.docId === docId
        ? thumbnailsData?.docCards?.filter((c) => !!c.previewUrl)
        : emptyThumbnails

    return sample(thumbs)
  }, [thumbnailsData?.docCards, docId, emptyThumbnails])

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const fetchThumbnails = useCallback((docId: string) => {
    setDocId(docId)
  }, [])

  const getThumbnails = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (findDocId: string) => {
      return docId === findDocId ? cardThumbnails : emptyThumbnails
    },
    [cardThumbnails, docId, emptyThumbnails]
  )

  return {
    fetchThumbnails,
    getThumbnails,
  }
}

export function sample<T>(arr: T[], size: number = 12): T[] {
  if (arr.length <= size) {
    return arr
  }

  const step = Math.floor(arr.length / size)
  const res: T[] = []
  for (let i = 0; i < arr.length; i = i + step) {
    res.push(arr[i])
    if (res.length === size) {
      break
    }
  }

  return res
}
