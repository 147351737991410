import {
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sortBy } from 'lodash'
import { useState } from 'react'

import { WorkspaceInvitation } from 'modules/api'

export const PendingInvitationsList = ({
  invitations,
  dateFormatterFn,
}: {
  invitations: WorkspaceInvitation[]
  dateFormatterFn: (date: string) => string
}) => {
  const [sortField, setSortField] = useState<'createdTime' | 'email'>('email')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')

  const sortedInvitations =
    sortDirection === 'asc'
      ? sortBy(invitations, sortField)
      : sortBy(invitations, sortField).reverse()

  if (sortedInvitations.length === 0) {
    return (
      <Box p={4} background="gray.50">
        <Text my={8} textAlign="center">
          No pending invitations.
        </Text>
      </Box>
    )
  }
  return (
    <TableContainer>
      <Table
        variant="simple"
        colorScheme="blackAlpha"
        size="sm"
        whiteSpace="normal"
      >
        <Thead>
          <Tr>
            <Th>
              <Button
                ml={-3}
                size="sm"
                color="gray.500"
                variant="ghost"
                colorScheme="blackAlpha"
                aria-label="sort"
                onClick={() => {
                  setSortField('email')
                  setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                }}
                rightIcon={
                  <Box color={sortField === 'email' ? 'gray.700' : 'gray.300'}>
                    <FontAwesomeIcon
                      icon={
                        sortField === 'email'
                          ? sortDirection === 'asc'
                            ? solid('caret-down')
                            : solid('caret-up')
                          : solid('sort')
                      }
                    />
                  </Box>
                }
              >
                Email
              </Button>
            </Th>
            <Th>
              {' '}
              <Button
                ml={-3}
                size="sm"
                color="gray.500"
                variant="ghost"
                colorScheme="blackAlpha"
                aria-label="sort"
                onClick={() => {
                  setSortField('createdTime')
                  setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                }}
                rightIcon={
                  <Box color={sortField === 'email' ? 'gray.700' : 'gray.300'}>
                    <FontAwesomeIcon
                      icon={
                        sortField === 'createdTime'
                          ? sortDirection === 'asc'
                            ? solid('caret-down')
                            : solid('caret-up')
                          : solid('sort')
                      }
                    />
                  </Box>
                }
              >
                Last sent
              </Button>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedInvitations &&
            sortedInvitations?.length > 0 &&
            sortedInvitations.map((invitation, i) => {
              return (
                <Tr key={invitation.id}>
                  <Td>{invitation.email}</Td>
                  <Td>{dateFormatterFn(invitation.createdTime)}</Td>
                </Tr>
              )
            })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
