import { useEffect, useState } from 'react'

import { DocSortField, SortDirection } from 'modules/api'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import { SidebarTabs } from '../components/Sidebar/Sidebar'
import { DEFAULT_DATE_DISPLAY_OPTION } from '../constants'
import {
  DateDisplayOptionType,
  FilterByTypes,
  ViewPreferenceTypes,
} from '../types'

export const useDocsDisplayConfig = ({ currentChannelId, activeTab }) => {
  const [viewPreference, setViewPreference] =
    useLocalStorage<ViewPreferenceTypes>(
      USER_SETTINGS_CONSTANTS.viewPreferences,
      'grid'
    )
  const [sortBy, setSortBy] = useState<DocSortField>(DocSortField.EditedTime)
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Desc
  )
  const [filterBy, setFilterBy] = useState<FilterByTypes>(() => {
    return currentChannelId ? 'all' : 'recent'
  })
  const [dateDisplayOption, setDateDisplayOption] =
    useState<DateDisplayOptionType>(DEFAULT_DATE_DISPLAY_OPTION)

  useEffect(() => {
    // Reset filter to "all" if the currentChannelId changes
    if (activeTab === SidebarTabs.ALL) {
      setFilterBy('recent')
    } else {
      setFilterBy('all')
    }
  }, [currentChannelId, activeTab, setFilterBy])

  useEffect(() => {
    // If the filterBy is changed to "Recent" or "Favorites", we will limit the date display options in the table
    if (filterBy === 'createdByYou') {
      setDateDisplayOption(DocSortField.CreatedTime)
      setSortBy(DocSortField.CreatedTime)
    } else if (filterBy === 'recent') {
      setDateDisplayOption(DocSortField.LastViewed)
    } else if (filterBy === 'favorites') {
      setDateDisplayOption(DocSortField.Favorited)
    } else {
      setSortBy(DocSortField.EditedTime)
      setDateDisplayOption(DocSortField.EditedTime)
    }
  }, [filterBy, setSortBy, setDateDisplayOption])

  return {
    viewPreference,
    setViewPreference,
    sortBy,
    setSortBy,
    sortDirection,
    filterBy,
    setFilterBy,
    setSortDirection,
    dateDisplayOption,
    setDateDisplayOption,
  }
}
