import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  useBreakpointValue,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FilterByTypes } from '../../../types'
import {
  FILTER_BY_OPTIONS,
  FILTER_BY_OPTIONS_ORDERED,
  FILTER_BY_OPTIONS_ORDERED__CHANNEL_VIEW,
} from './constants'

type CategoryFilterProps = {
  currentChannelId: string | null
  filterBy: FilterByTypes
  setFilterBy: (filterBy: FilterByTypes) => void
}
export const CategoryFilters = ({
  currentChannelId,
  filterBy,
  setFilterBy,
}: CategoryFilterProps) => {
  const showFilterMenu = useBreakpointValue({ base: true, xl: false })
  const options = currentChannelId
    ? FILTER_BY_OPTIONS_ORDERED__CHANNEL_VIEW
    : FILTER_BY_OPTIONS_ORDERED
  const selectedOption = FILTER_BY_OPTIONS?.[filterBy]?.label
  return (
    <Wrap
      id="filter-by-dropdown"
      zIndex="3"
      width={['100%', 'auto']}
      mt={[4, 0]}
    >
      {showFilterMenu ? (
        <Menu closeOnSelect={true}>
          <MenuButton
            as={Button}
            variant="ghost"
            size="sm"
            rightIcon={<FontAwesomeIcon icon={regular('chevron-down')} />}
            fontWeight="600"
            color="gray.700"
            leftIcon={<FontAwesomeIcon icon={regular('filter')} />}
          >
            {selectedOption}
          </MenuButton>
          <MenuList minWidth="240px">
            <MenuOptionGroup
              defaultValue={filterBy}
              title="Filter"
              type="radio"
              onChange={setFilterBy}
              value={filterBy}
            >
              {options.map((option) => (
                <MenuItemOption value={option.key} key={option.key}>
                  {option.label}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      ) : (
        options.map((option) => {
          const isSelected = option.key === filterBy
          return (
            <WrapItem key={option.key}>
              <Button
                fontWeight="600"
                color={isSelected ? 'trueblue.500' : 'gray.600'}
                bg={isSelected ? 'trueblue.50' : 'transparent'}
                size="sm"
                leftIcon={
                  <FontAwesomeIcon
                    icon={option.icon}
                    aria-label={option.label}
                  />
                }
                variant="ghost"
                onClick={() => setFilterBy(option.key)}
              >
                {option.label}
              </Button>
            </WrapItem>
          )
        })
      )}
    </Wrap>
  )
}
