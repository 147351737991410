import { ArrowBackIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Image,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Skeleton,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react'
import { capitalize, DOC_DISPLAY_NAME, LoadingToastInner } from '@gamma-app/ui'
import { useCallback, useState } from 'react'

import { Doc, useGetDocWithSnapshotQuery } from 'modules/api'
import { useSyncCardsRedux } from 'modules/cards'
import { getDocIdForEnv } from 'modules/example_decks/utils'
import { EditorCore } from 'modules/tiptap_editor'
import { GlobalCardStyles } from 'modules/tiptap_editor/extensions/Card/GlobalCardStyles'
import { useDuplicateDoc } from 'modules/tiptap_editor/utils/duplicate'
import SalPainting from 'publicImages/Sal-Painting-2x.png'

import { Api_Deck } from '../../types'

const MODAL_BODY_CLASS = 'template-preview-modal-body'

const TemplatePreviewSpinner = () => {
  return (
    <>
      <ModalHeader>
        <Skeleton w="80%" h="48px" />
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody display="flex" overflow="hidden">
        <Center w="100%" bg="gray.100">
          <Spinner />
        </Center>
      </ModalBody>
      <ModalFooter>
        <Skeleton w={48} h="42px" />
      </ModalFooter>
    </>
  )
}

const TemplatePreviewError = ({
  onClose,
  heading,
  errorMessage,
  onBackClick,
}: {
  onClose: () => void
  heading?: string
  errorMessage?: string
  onBackClick?: () => void
}) => {
  return (
    <>
      <ModalHeader>
        {Boolean(onBackClick) && (
          <IconButton
            aria-label="Back"
            icon={<ArrowBackIcon />}
            variant="ghost"
            onClick={onBackClick}
            mr={2}
          />
        )}
        {heading}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody display="flex" overflow="hidden">
        <Flex direction="column" justify="center" align="center" w="100%">
          <Image
            src={SalPainting.src}
            width="300px"
            alt="Sal, the Gamma mascot, perched on a rocket enjoying some screentime"
            mb={6}
          />
          <Heading size="sm" mb={2} textAlign="center">
            Oops, this is embarrassing.
          </Heading>
          <Text fontSize="md" color="gray.400" textAlign="center">
            We're sorry we couldn't load the template. Please try again later.
            <br />
            {Boolean(errorMessage) && errorMessage}
          </Text>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button variant="solid" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </>
  )
}

export const TemplatePreview = ({
  previewDeck,
  onBackClick,
  forceChannelId,
  onClose,
}: {
  previewDeck?: Api_Deck
  onBackClick?: () => void
  forceChannelId?: string | null
  onClose: () => void
}) => {
  const [isCloning, setIsCloning] = useState<boolean>(false)
  const duplicateDoc = useDuplicateDoc()
  const docIdToUse = getDocIdForEnv(previewDeck)
  const {
    data: docData,
    loading: docLoading,
    error: docError,
  } = useGetDocWithSnapshotQuery({
    variables: {
      id: docIdToUse as string,
    },
    skip: !docIdToUse,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  })
  const doc = docData?.doc
  const isLoading = !previewDeck || docLoading
  const error = docError || docError
  const snapshotContent = doc?.currentSnapshot?.content.default
  const toast = useToast()

  useSyncCardsRedux(docData?.doc?.cards)

  const onClone = useCallback(() => {
    if (!doc || !snapshotContent) return

    setIsCloning(true)

    const duplicateToast = toast({
      id: `duplicate-toast-${doc.id}`,
      position: 'top',
      duration: null,
      render: function LoadingToast({ onClose, id }) {
        return (
          <LoadingToastInner
            title="Duplicating..."
            isClosable={false}
            onClose={onClose}
            id={id}
          />
        )
      },
    }) as string

    duplicateDoc(doc.id, snapshotContent, forceChannelId)
      .then((result) => {
        if (!result?.data?.duplicateDoc) {
          console.error('[TemplatePreview] handleDuplicateDoc error')
          return
        }
        const { id } = result?.data?.duplicateDoc
        console.debug(
          `[TemplatePreview] ${capitalize(
            DOC_DISPLAY_NAME
          )} duplicated. New doc id (${id}). Navigating to Editor`
        )
        toast.update(duplicateToast, {
          status: 'success',
          description: 'Success!',
          duration: 5000,
        })
        window.open(`/docs/${id}`, '_blank')
      })
      .finally(() => {
        onClose()
      })
  }, [doc, duplicateDoc, forceChannelId, snapshotContent, toast, onClose])

  if (isLoading) {
    return <TemplatePreviewSpinner />
  }

  if (error || !previewDeck || !doc || !snapshotContent) {
    if (error) {
      console.error(
        `[TemplatePreview] Error loading template: ${error.message}`
      )
    }
    return (
      <TemplatePreviewError
        onClose={onClose}
        onBackClick={onBackClick}
        heading={
          previewDeck?.title ? previewDeck.title : 'Error loading template'
        }
        errorMessage={error?.message}
      />
    )
  }

  return (
    <>
      <ModalHeader>
        {Boolean(onBackClick) && (
          <IconButton
            aria-label="Back"
            icon={<ArrowBackIcon />}
            variant="ghost"
            onClick={onBackClick}
            mr={2}
          />
        )}
        {previewDeck.title}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody display="flex" overflow="hidden">
        <Flex
          w="100%"
          direction="column"
          className={`${MODAL_BODY_CLASS}`}
          overflowX="hidden"
          align="center"
          overflowY="auto"
          justifyContent={'flex-start'}
          h="100%"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="gray.200"
        >
          <GlobalCardStyles />
          <EditorCore
            animationsEnabled={false}
            isStatic={true}
            key={doc.id}
            initialContent={snapshotContent}
            doc={doc as Doc}
            docId={doc.id}
            readOnly={true}
            scrollingParentSelector={`.${MODAL_BODY_CLASS}`}
          />
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button variant="solid" onClick={onClone} isDisabled={isCloning}>
          Use this template
        </Button>
      </ModalFooter>
    </>
  )
}
