import { Tab, TabList, Tabs } from '@chakra-ui/react'
import React from 'react'

import { SimplifiedCategory } from 'modules/example_decks'

export const TemplateBrowserSidebar = ({
  categories,
  setCurrentCategoryId,
  currentCategoryId,
}: {
  categories?: SimplifiedCategory[]
  setCurrentCategoryId: React.Dispatch<
    React.SetStateAction<SimplifiedCategory['id']>
  >
  currentCategoryId
}) => {
  const currentTabIndex = categories?.findIndex(
    (c) => c.id === currentCategoryId
  )
  if (!categories || categories?.length === 0) {
    console.error('[TemplateBrowserSidebar] categories is empty')
    return null
  }
  return (
    <Tabs
      index={currentTabIndex}
      variant="soft-rounded"
      orientation="vertical"
      size="md"
      w="260px"
    >
      <TabList w="100%">
        {categories?.length > 0 &&
          categories.map(({ title, id, decks }) => {
            return (
              <Tab
                flexDirection="column"
                borderRadius="base"
                border="none"
                onClick={() => setCurrentCategoryId(id)}
                alignItems="flex-start"
                width="100%"
                textAlign="left"
                key={id}
                display="block"
              >
                {title} {`${decks?.length > 0 ? `(${decks.length})` : ''}`}
              </Tab>
            )
          })}
      </TabList>
    </Tabs>
  )
}
