import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { Channel } from 'modules/api'

/**
 * Redirects if currentChannel.workspaceId does not match current workspaceId.
 */
export const useRedirectIfChannelNotInWorkspace = ({
  currentChannel,
  currentWorkspaceId,
}: {
  currentChannel?: Channel
  currentWorkspaceId?: string
}) => {
  const { replace } = useRouter()
  useEffect(() => {
    if (!currentWorkspaceId || !currentChannel) {
      return
    }

    if (currentChannel.workspaceId !== currentWorkspaceId) {
      replace('/')
    }
  }, [currentChannel, currentWorkspaceId, replace])
}
