import { useRouter } from 'next/dist/client/router'
import { useEffect } from 'react'

import { withHomeLayout } from 'layout'
import { useLastHomeUrlListener } from 'modules/routing/hooks'
import { useUserContext } from 'modules/user'
import { Home, HomeSkeleton } from 'sections/home_v2'
import { useSSRMounted } from 'utils/hooks'

export const HomePage = () => {
  const { push } = useRouter()
  const { user, isUserLoading } = useUserContext()

  useLastHomeUrlListener(true)

  useEffect(() => {
    // https://nextjs.org/docs/messages/no-router-instance
    // You can't use push during pre-rendering; wrapping this call inside
    // useEffect ensures that the router method is only executed client side.
    if (!isUserLoading && !user) {
      // redirect to signin
      push('/signin')
    }
  }, [isUserLoading, user, push])

  if (!useSSRMounted()) {
    return <HomeSkeleton key="home-skeleton" />
  }

  return <Home key={'home-v2'} />
}

export default withHomeLayout(HomePage)
