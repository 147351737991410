import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AvatarGroupAutoOverflow, DOC_DISPLAY_NAME } from '@gamma-app/ui'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useCallback } from 'react'

import { config } from 'config'
import { Channel, ExistingWorkspace } from 'modules/api'
import { useCreateDocAndNavigate } from 'modules/api/hooks'
import { TemplatesModal } from 'modules/example_decks'
import { useFeatureFlag } from 'modules/featureFlags'
import { isMobileDevice } from 'utils/deviceDetection'

import { ManageChannelMenu } from '../../channels'
import { GooglePicker } from '../../import/google'
import { TabInfo } from '../../Sidebar/Sidebar'

type HeaderProps = {
  currentWorkspace?: ExistingWorkspace
  description: TabInfo['description']
  displayName: TabInfo['displayName']
  icon: TabInfo['icon']
  showCreateButton: TabInfo['showCreateButton']
  channel?: Channel
  isChannelPreview: boolean
}

export const Header = ({
  currentWorkspace,
  description,
  displayName,
  icon,
  showCreateButton,
  channel,
  isChannelPreview,
}: HeaderProps) => {
  const {
    isOpen: isTemplateBrowserModalOpen,
    onOpen: onTemplateBrowserModalOpen,
    onClose: onTemplateBrowserModalClose,
  } = useDisclosure({
    id: 'template-browser-modal',
  })
  const toast = useToast()
  const docImportEnabled = useFeatureFlag('docImport')
  const examplesV2Enabled = useFeatureFlag('examplesV2')

  const [createDocAndNavigate, { data: createDocData }] =
    useCreateDocAndNavigate()

  const isCreateButtonDisabled = Boolean(
    createDocData?.createDoc || !currentWorkspace
  )

  const onImportSelectionMade = useCallback(
    (html?: string, title?: string) => {
      if (!html) return

      createDocAndNavigate({ html, channelId: channel?.id, title })
    },
    [channel?.id, createDocAndNavigate]
  )

  const createBlank = useCallback(
    () => createDocAndNavigate({ channelId: channel?.id }),
    [createDocAndNavigate, channel?.id]
  )

  return (
    <Flex flex="1" mb={isMobileDevice ? 0 : 4} direction={'column'}>
      <Box w={['100%', 'auto']}>
        <HStack h="1.5em">
          <Heading
            fontSize="lg"
            fontWeight="600"
            data-testid="docs-view-header"
          >
            {icon && <FontAwesomeIcon icon={icon} aria-label={displayName} />}{' '}
            <Text as="span" ml={2}>
              {displayName}
            </Text>
          </Heading>
          <Spacer />
          {!isChannelPreview && channel && (
            <ManageChannelMenu
              channel={channel}
              workspaceId={currentWorkspace?.id}
            />
          )}
        </HStack>
        {description && (
          <Text color="gray.600" fontSize="sm">
            {description}
          </Text>
        )}
        {channel && (
          <Stack direction="row" alignItems="center" mt={4}>
            <AvatarGroupAutoOverflow
              size="xs"
              avatars={channel?.members || []}
              max={6}
            />
            <Text fontSize="sm" color="gray.400" ml={2}>
              {channel.memberCount}{' '}
              {channel.memberCount === 1 ? 'member' : 'members'}
            </Text>
          </Stack>
        )}
      </Box>
      {/* Only render the stack if the create button is enabled. An empty stack takes up space! */}
      {showCreateButton && (
        <Stack direction={{ base: 'column', lg: 'row' }} spacing={2} mt={4}>
          <Button
            data-testid="create-doc-button"
            disabled={isCreateButtonDisabled}
            variant="solid"
            onClick={createBlank}
            leftIcon={<FontAwesomeIcon icon={regular('plus')} />}
          >
            New {DOC_DISPLAY_NAME}
          </Button>
          {examplesV2Enabled && (
            <Button
              data-testid="create-from-template-button"
              disabled={isCreateButtonDisabled}
              variant="plain"
              onClick={onTemplateBrowserModalOpen}
              leftIcon={<FontAwesomeIcon icon={regular('plus')} />}
            >
              New from template
            </Button>
          )}
          {showCreateButton && docImportEnabled && (
            <GoogleOAuthProvider
              clientId={config.GOOGLE_DRIVE_IMPORT_CLIENT_ID}
            >
              <GooglePicker
                onChange={onImportSelectionMade}
                onAuthFailed={(error) => {
                  console.log('Google auth failed:', error)
                  toast({
                    title: `Couldn't authenticate with Google Error: ${error}`,
                    status: 'error',
                    duration: 3000,
                    position: 'top',
                    isClosable: false,
                  })
                }}
                createBlank={createBlank}
                disabled={isCreateButtonDisabled}
              />
            </GoogleOAuthProvider>
          )}
        </Stack>
      )}

      <TemplatesModal
        isOpen={isTemplateBrowserModalOpen}
        onClose={onTemplateBrowserModalClose}
        forceChannelId={channel?.id}
      />
    </Flex>
  )
}
