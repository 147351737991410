import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { CHANNEL_DISPLAY_NAME } from '@gamma-app/ui'
import Router from 'next/router'
import React, { ChangeEvent, useCallback, useRef, useState } from 'react'

import { useUpdateChannelMutation } from 'modules/api'
import { generateChannelUrl } from 'utils/url'

export const RenameChannelModal = ({ channel, workspaceId, onClose }) => {
  const toast = useToast()
  const [nameField, setNameField] = useState<string>(() => channel?.name)

  const [updateChannel, { loading }] = useUpdateChannelMutation()

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setNameField(e.target.value)
  }, [])
  const initialRef = useRef<HTMLInputElement>(null)

  const isError = Boolean(errorMessage)

  const handleRenameChannel = useCallback(() => {
    if (!workspaceId || !channel?.id) {
      console.error(
        `[onRenameChannel] Cannot rename channel. OrgId: "${workspaceId}" channelId: "${channel?.id}"`
      )
      return
    }
    updateChannel({
      variables: {
        input: {
          id: channel?.id,
          workspaceId: workspaceId as string,
          name: nameField,
        },
      },
    })
      .then(({ data }) => {
        Router.replace(
          generateChannelUrl({
            id: channel?.id,
            slug: data?.updateChannel.slug,
          })
        )
        toast({
          title: `${CHANNEL_DISPLAY_NAME} successfully renamed to "${data?.updateChannel?.name}".`,
          status: 'success',
          duration: 3000,
          position: 'top',
          isClosable: true,
        })
        onClose()
      })
      .catch((err) => {
        setErrorMessage(err.message)
      })
  }, [workspaceId, channel?.id, updateChannel, nameField, toast, onClose])

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={true}
      initialFocusRef={initialRef}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Rename {CHANNEL_DISPLAY_NAME}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex mt={4} w="100%" direction="column">
            <FormControl isInvalid={isError}>
              <Input
                value={nameField}
                ref={initialRef}
                onChange={onChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleRenameChannel()
                }}
              />
              {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              isLoading={loading}
              variant="solid"
              onClick={handleRenameChannel}
            >
              Rename
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
