import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  InputGroup,
  InputLeftElement,
  Text,
  useToast,
} from '@chakra-ui/react'
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteRefMethods,
} from '@choc-ui/chakra-autocomplete'
import { validate as validateEmail } from 'email-validator'
import { motion } from 'framer-motion'
import { uniqBy } from 'lodash'
import { useCallback, useRef, useState } from 'react'

import { useInviteMembersMutation } from 'modules/api'
import { SearchBarCollaboratorTag } from 'modules/sharing/components/SharePanel/SearchBarTag'

const MotionBox = motion(Box)
interface UserToInvite {
  email: string
}

export const InviteByEmail = ({ workspace }) => {
  const toast = useToast()
  const autocompleteRef = useRef<AutoCompleteRefMethods>()
  const inputRef = useRef<HTMLInputElement>(null)
  const [selectedItems, setSelectedItems] = useState<UserToInvite[]>([])
  const [inputValue, setInputValue] = useState('')
  const [inviteMembers, { loading }] = useInviteMembersMutation()

  const currentValidItems = selectedItems
    .concat({ email: inputValue })
    .filter((u) => ('email' in u ? validateEmail(u.email || '') : true))

  const showControls = Boolean(selectedItems.length)
  const hasSomeValidItems = Boolean(currentValidItems.length)

  const shouldDisableInput = loading

  const inviteSelectedUsers = useCallback(async () => {
    if (selectedItems.length === 0) return

    // Grab the current input value in case the user forgot to submit the text input
    const usersToInvite = [
      ...selectedItems,
      { email: inputValue.trim() },
    ].filter((u): u is UserToInvite => {
      return 'email' in u && validateEmail(u.email || '')
    })

    return inviteMembers({
      variables: {
        workspaceId: workspace.id,
        invitees: usersToInvite,
      },
      refetchQueries: ['GetWorkspace'],
    }).then(() => {
      toast({
        title: `${
          usersToInvite.length === 1 ? 'Invitation' : 'Invitations'
        }  sent`,
        description: (
          <Text>
            {usersToInvite.map((u) => (
              <Text key={u.email}>{u.email}</Text>
            ))}
          </Text>
        ),
        status: 'success',
        position: 'top',
        isClosable: true,
        duration: 9000,
      })
    })
  }, [inputValue, inviteMembers, workspace.id, selectedItems, toast])

  const onInviteClick = useCallback(async () => {
    return Promise.all([inviteSelectedUsers()])
      .then(() => {
        setSelectedItems([])
        setInputValue('')
        autocompleteRef.current?.resetItems(true)
      })
      .catch((e) => {
        console.warn(e)
      })
  }, [inviteSelectedUsers])

  const onRemoveClick = useCallback((email) => {
    setSelectedItems((prev) => {
      return prev.filter((u) => u.email !== email)
    })
  }, [])

  const addToSelectedItems = useCallback((itemOrItems: UserToInvite[]) => {
    setSelectedItems((prev) => {
      return uniqBy([...prev, ...itemOrItems], 'email')
    })
  }, [])

  return (
    <Box my={4} mb={6}>
      <Text mb={2} fontSize="sm" color="gray.500">
        Invite by email address
      </Text>
      <Flex direction="row" w="100%" alignItems="flex-start">
        <InputGroup w="100%" justifyContent="center" flex={1}>
          <AutoComplete
            ref={autocompleteRef}
            values={selectedItems}
            maxSelections={100}
            maxSuggestions={20}
            creatable={true}
            multiple={true}
            freeSolo={true}
            suggestWhenEmpty={false}
            openOnFocus={true}
            filter={(_query: string, itemValue: string) => {
              return !selectedItems.find((s) => s.email === itemValue)
            }}
            // @ts-ignore
            onSelectOption={({ item }: OnSelectOptionArgs) => {
              addToSelectedItems([{ email: item.value }])
              setInputValue('')
            }}
            onTagRemoved={(value) => {
              setSelectedItems((prev) =>
                [...prev].filter((c) => {
                  return c.email !== value
                })
              )
            }}
          >
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              h="100%"
              flexDirection="column"
            >
              {/**
               * Use a flex 1 div to slam the Search Icon to the bottom.
               * Necessary until this libary supports InputGroup inside AutoCompleteInput
               * See https://github.com/anubra266/choc-autocomplete/issues/63
               */}
              <Flex flex={1} />
              <Flex h={10} py={4} alignItems="center">
                <SearchIcon w={10} />
              </Flex>
            </InputLeftElement>
            <AutoCompleteInput
              ref={inputRef}
              data-testid="autocomplete-collaborators-input"
              cursor={shouldDisableInput ? 'not-allowed' : undefined}
              disabled={shouldDisableInput}
              w={'100%'}
              pl={5}
              wrapStyles={{
                px: 2,
              }}
              fontSize="md"
              placeholder="Add people"
              transition="width 1s ease-in-out"
              value={inputValue}
              onKeyDown={(e) => {
                const { key } = e
                if (key === 'Enter' && inputValue.length === 0) {
                  // No op if empty string
                  e.preventDefault()
                  return
                } else if (
                  key === 'Backspace' &&
                  inputValue.length === 0 &&
                  selectedItems.length > 0
                ) {
                  // Delete the last item
                  const lastItem = selectedItems.slice(-1).pop()
                  if (lastItem) {
                    autocompleteRef.current?.removeItem(lastItem.email)
                  }
                }
              }}
              onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                e.preventDefault()
                e.stopPropagation()
                const value = e.clipboardData.getData('Text')
                const parts = value.trim().split(/[,|;\s]+/)
                addToSelectedItems([...parts.map((email) => ({ email }))])
                setInputValue('')
              }}
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = target
                setInputValue(value)
              }}
            >
              {selectedItems.map((selectedItem, idx) => {
                return (
                  <SearchBarCollaboratorTag
                    idx={idx}
                    selectedCollaborator={selectedItem}
                    handleRemoveClick={() => {
                      onRemoveClick(selectedItem.email)
                    }}
                    key={selectedItem.email}
                  />
                )
              })}
            </AutoCompleteInput>
          </AutoComplete>
        </InputGroup>
        <MotionBox
          overflowX="hidden"
          transition="slow"
          animate={{
            maxWidth: showControls ? '240px' : '0px',
          }}
        >
          <Box ml={2}>
            <Button
              variant="solid"
              onClick={onInviteClick}
              isDisabled={!hasSomeValidItems}
              isLoading={false}
              data-testid="add-selected-items"
            >
              Invite
            </Button>
          </Box>
        </MotionBox>
      </Flex>
    </Box>
  )
}
