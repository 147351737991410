import { Button, ButtonProps, Link, Text } from '@chakra-ui/react'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import NextLink from 'next/link'
import Router from 'next/router'
import React, { MutableRefObject, useCallback } from 'react'

import { SidebarButton } from './SidebarButton'

interface SidebarItemProps extends ButtonProps {
  tabKey: string
  isSelected?: boolean
  isDropActive?: boolean
  href: string
}

export const SidebarItem = React.forwardRef(
  (
    {
      tabKey,
      isSelected,
      isDropActive = false,
      href,
      children,
      ...props
    }: SidebarItemProps,
    ref: MutableRefObject<HTMLButtonElement>
  ) => {
    const onClick = useCallback(
      (e) => {
        e.preventDefault()
        Router.push(href)
      },
      [href]
    )
    return (
      <NextLink href={href} passHref>
        <SidebarButton
          ref={ref}
          isSelected={isSelected}
          isDropActive={isDropActive}
          dataTestId={`sidebar-${tabKey}-tab${isSelected ? '--selected' : ''}`}
          onClick={onClick}
          {...props}
        >
          {children}
        </SidebarButton>
      </NextLink>
    )
  }
)

SidebarItem.displayName = 'SidebarItem'
