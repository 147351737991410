import { ToastId, useToast } from '@chakra-ui/react'
import Router, { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

import {
  useGetChannelQuery,
  useGetChannelsQuery,
  ChannelsDocument,
} from 'modules/api'

import { ChannelPreviewToast } from '../components/toasts/ChannelPreviewToast'
import { INITIAL_TAB } from '../constants'
import { useChannelMutations } from './useChannelMutations'

const useChannelPreview = ({
  currentChannelId,
  activeChannel,
  workspaceId,
}) => {
  const toastIdRef = useRef<ToastId>()
  const toast = useToast()
  const { joinChannel } = useChannelMutations()
  const { data, called, error, loading } = useGetChannelQuery({
    variables: {
      id: currentChannelId,
      workspaceId,
    },
    skip: activeChannel || !currentChannelId || !workspaceId,
  })

  // IMPORTANT: Only show channel preview when
  // 1) user has signified intent to look at a URL (via presence of currentChannelId)
  // 2) there is no matching active channel
  const channelPreview =
    currentChannelId && !activeChannel && data?.channel ? data.channel : null

  useEffect(() => {
    if (error) {
      console.error('[useChannelsData] Error fetching channelPreview', error)
    }
    // Redirect to initial tab if we can't find the channel, or if we found the channel but it's archived
    if (error || (called && channelPreview && channelPreview.archived)) {
      Router.replace(`/#${INITIAL_TAB}`)
    }
  }, [error, channelPreview, called, loading])

  useEffect(() => {
    const shouldShowChannelToast = currentChannelId && channelPreview

    if (!shouldShowChannelToast && toastIdRef.current) {
      toast.close(toastIdRef.current)
      toastIdRef.current = undefined
    }

    if (shouldShowChannelToast) {
      if (!toastIdRef.current) {
        toastIdRef.current = toast({
          duration: null,
          // eslint-disable-next-line
          render: () => {
            return (
              <ChannelPreviewToast
                workspaceId={workspaceId}
                currentChannelId={currentChannelId}
                channelPreview={channelPreview}
                joinChannel={joinChannel}
                toastId={toastIdRef.current}
              />
            )
          },
        })
      } else if (toastIdRef.current) {
        toast.update(toastIdRef.current, {
          duration: null,
          // eslint-disable-next-line
          render: () => {
            return (
              <ChannelPreviewToast
                workspaceId={workspaceId}
                currentChannelId={currentChannelId}
                channelPreview={channelPreview}
                joinChannel={joinChannel}
                toastId={toastIdRef.current}
              />
            )
          },
        })
      }
    }
    return () => {
      // Close the toast
      if (toastIdRef.current) {
        toast.close(toastIdRef?.current)
      }
    }
  }, [currentChannelId, channelPreview, joinChannel, toast, workspaceId])

  return { channelPreview }
}
export const useChannelsData = ({ workspaceId, user }) => {
  const { query } = useRouter()
  const channelSlug = Array.isArray(query.channelSlug)
    ? query.channelSlug[0]
    : query.channelSlug
  const currentChannelId = channelSlug ? channelSlug.slice(-15) : null

  const {
    data: channelsData,
    loading: isChannelsLoading,
    subscribeToMore,
  } = useGetChannelsQuery({
    variables: {
      workspaceId,
    },
    skip: !user || !user.organizations || !workspaceId,
    returnPartialData: true,
  })

  useEffect(() => {
    if (!process.browser || !subscribeToMore) return

    return subscribeToMore({
      document: ChannelsDocument,
      variables: { workspaceId },
    })
  }, [subscribeToMore, workspaceId])

  // When leaving channels, the apollo cache may contain channels that
  // you are no longer a member of, so filter them out here.
  const channels = (channelsData?.channels || [])
    .filter((c) => c.isMember && !c.archived)
    .sort((a, b) =>
      // Mimic the API sort order for DocChannels
      // https://github.com/gamma-app/gamma/blob/25763537fd84edcd73ca4c99c2a79df4890ed5d6/packages/server/src/docs/docs.service.ts#L632-L635
      a.slug.localeCompare(b.slug, 'en')
    )

  const activeChannel = channels.find((c) => c.id === currentChannelId)
  const { channelPreview } = useChannelPreview({
    currentChannelId,
    activeChannel,
    workspaceId,
  })

  return {
    channelPreview,
    currentChannelId,
    channels,
    isChannelsLoading,
    activeChannel,
  }
}
